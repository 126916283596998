import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import ProgramsTable from './ProgramsTable';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { PROGRAMS } from 'litterals';
import useViewSubscription from 'hooks/usecase/useViewSubscription';
import { roleList } from 'utils/constUtils';

const ProgramsCoachee = ({ user }) => {
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 25,
    status: ['done', 'draft', 'pending', 'in progress'],
  });
  const { actions, query, state } = useViewSubscription({ initialFilterState: queryParams });
  const { t } = useTranslation();

  const updateCurrentPage = ({ page }) => {
    return actions.updateFilterSubscription({
      page: page,
    });
  };

  return (
    <Paper elevation={3} sx={{ m: '24px', p: '12px', pb: '24px', position: 'relative' }}>
      <Typography component="h1" variant="h4" fontWeight="bold" sx={{ pl: '12px' }}>
        {t(PROGRAMS)}
      </Typography>
      <ProgramsTable
        user={user}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        extraColumns={['Coach', 'Next-Session']}
        updateFilter={actions.updateFilterSubscription}
        programs={query.data ? query.data.rows : []}
        isLoading={query.isLoading}
        sx={{ height: '70vh' }}
        asCoachee
        role={roleList.COACHEE}
        updateCurrentPage={updateCurrentPage}
        currentPage={state.filterSubscriptionState.currentPage}
        totalPage={query.data ? query.data.totalPages : 0}
        totalItems={query.data && query.data.totalItems}
      />
    </Paper>
  );
};

export default ProgramsCoachee;
