import React from 'react';
import Button from 'ui/components/Button/Button';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CalendarConnectionButton = ({ sx = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button variant="tertiary" sx={{ padding: '6px 4px', ...sx }} onClick={() => navigate('/settings#calendar')}>
      <CalendarMonthOutlinedIcon sx={{ marginRight: '6px' }} />

      {t('connect_calendar')}
    </Button>
  );
};

export default CalendarConnectionButton;
