import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

export const MentoringStatusChip = ({ status, style = {} }) => {
  const { t, i18n } = useTranslation();

  const i18nStatusKey = `mentoring_status_chip.${status.replaceAll(' ', '_').toLowerCase()}`;

  const statusLabel = i18n.exists(i18nStatusKey) ? t(i18nStatusKey) : status;

  const statusColor = {
    draft: 'ternary',
    pending: '#AC772D',
    ['in progress']: '#067647',
    Started: 'success',
    done: '#344054',
    deactivated: '#E45C5D',
  };

  const bgColor = {
    draft: 'ternary',
    pending: '#FFF2DC',
    ['in progress']: '#ECFDF3',
    Started: 'success',
    done: '#F2F2F2',
    deactivated: '#FFE4E1',
  };

  const borderColor = {
    draft: 'ternary',
    pending: '#AC772D99',
    ['in progress']: '#0A7A3D99',
    Started: 'success',
    done: '#32313B99',
    deactivated: '#FFE4E1',
  };

  return (
    <Chip
      variant="outlined"
      label={
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {status === 'in progress' && (
            <Box sx={{ borderRadius: '50%', height: '6px', width: '6px', marginRight: '6px', background: '#17B26A' }} />
          )}
          {statusLabel.trim()}
        </Box>
      }
      style={{
        backgroundColor: bgColor[status],
        color: statusColor[status],
        borderColor: borderColor[status],
        minWidth: '70px',
        height: '20px',
        fontSize: '12px',
        ...style,
      }}
      sx={{ '.MuiChip-label': { paddingLeft: '8px', paddingRight: '8px' } }}
    />
  );
};
