const styles = {
  container: {
    padding: '18px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    boxSizing: 'border-box',
  },
  emptyStateButton: {
    marginTop: '8px',
  },
  title: {
    fontWeight: 600,
    fontSize: '16px',
  },
};

export default styles;
