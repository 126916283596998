import React from 'react';
import CoachView from 'ui/components/shared/coaches/CoachView';
import Box from 'ui/components/system/Box';
import { useParams, useLocation } from 'react-router-dom';
import { useCoachQuery, useMeQuery, useGetCompany } from 'hooks/queries';

import GoBackButton from 'ui/components/shared/GoBackButton';

const CoachPage = () => {
  const { coachId } = useParams();
  const { data: user, isLoading: isLoadingUser } = useMeQuery();
  const { data: coach, isLoading } = useCoachQuery(coachId);
  const { hash } = useLocation();
  const { data: company } = useGetCompany(coach?.companyId, { enabled: !isLoadingUser && !isLoading && !user.company });

  return (
    <Box
      variant=""
      sx={{
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '2%',
        height: '84vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      <GoBackButton sx={{ mt: '12px', ml: '12px' }} />
      <Box sx={{ padding: '2rem', margin: '2rem', background: '#fff' }}>
        <CoachView
          scrollToAvailabilities={hash === '#showAvailabilities'}
          redBorderNoAvailabilities={hash === '#showAvailabilities'}
          availabilitiesEditable
          coach={coach}
          userRole={user.role}
          editButtonDisplay
          isLoading={isLoading}
          company={user.company || company}
          isCurrentUser={user.id === coach?.id}
        />
      </Box>
    </Box>
  );
};

export default CoachPage;
