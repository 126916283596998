import React from 'react';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import styles from './AvailabilityChip.styles';

const AvailabilityChip = ({ count, showIcon = false, sx = {} }) => {
  const { t } = useTranslation();

  return (
    <Chip
      sx={{ ...styles.chip, ...sx }}
      size="small"
      icon={showIcon ? <PeopleAltOutlinedIcon sx={styles.chipIcon} /> : undefined}
      label={t('availabilities_count', { count })}
      color={count ? 'info' : 'default'}
      variant="outlined"
    />
  );
};

export default AvailabilityChip;
