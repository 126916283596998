import React from 'react';

import styles from './Select.styles';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const index = ({ variant = 'standard', ...props }) => {
  const { t } = useTranslation();
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    ref: props.ref,
    defaultValue: props.defaultValue,
  });

  return (
    <FormControl variant={variant} fullWidth>
      <InputLabel id="demo-simple-select-standard-label">{props.label}</InputLabel>
      <Select
        inputRef={ref}
        error={!!error}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        label={props.label}
        onChange={onChange}>
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <Box sx={{ display: 'flex' }}>
              {option.src && (
                <Avatar
                  src={option.src}
                  sx={{ width: '20px', height: '20px', marginRight: '8px', marginLeft: '2px' }}
                />
              )}
              {t(option.label)}
            </Box>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default index;
