import React from 'react';
import useLogin from 'hooks/usecase/useLogin';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useVerifyMeQuery } from 'hooks/queries';
import { Typography, Box } from '@mui/material';
import AuthLayout from '../login/AuthLayout';
import PasswordForm from './PasswordForm';
import SendResetLinkForm from './SendResetLinkForm';
import SignInWithMicrosoftButton from 'ui/components/form/SignInWithMicrosoftButton';
import { useTranslation } from 'react-i18next';
import { DEFINE_NEW_PASSWORD } from 'litterals';
import { getTypeAuth } from 'utils/auth';
import Button from 'ui/components/Button/Button';

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
};

const ResetPasswordPage = () => {
  let { tokenId, ssoType, company } = useParams();
  const { isError } = useVerifyMeQuery({ id: tokenId });
  const { t } = useTranslation();

  // Submit reset password
  const { actions: loginAction } = useLogin();
  const { handleSubmit, control, watch, setError } = useForm({
    defaultValues: { password: '', password_repeat: '' },
  });
  const onSubmitResetPassword = handleSubmit((data) => {
    loginAction.onUpdatePassword({ newPassword: data.password, token: tokenId, setError });
  });

  const onSubmitMicrosoft = () => {
    if (ssoType === 'microsoft') {
      const type = getTypeAuth(company);
      loginAction.onSubmitMicrosoftWithToken(tokenId, type);
    }
    if (ssoType === 'saml') {
      const type = getTypeAuth(company);
      loginAction.onSubmitSamlWithToken(tokenId, type);
    } else {
      loginAction.onSubmitMicrosoftWithToken(tokenId);
    }
  };

  return (
    <AuthLayout>
      <Box sx={styleBox} component="div">
        {isError ? (
          <SendResetLinkForm t={t} />
        ) : (
          <>
            <Typography
              component="h3"
              sx={{
                color: '#828282',
                fontSize: '16px',
                lineHeight: '24px',
                letter: '0.15px',
                paddingInline: '12px',
              }}>
              {t(DEFINE_NEW_PASSWORD)}
            </Typography>
            {ssoType !== 'microsoft' && ssoType !== 'saml' && (
              <PasswordForm control={control} onSubmit={onSubmitResetPassword} watch={watch} t={t} />
            )}
            {!company && <SignInWithMicrosoftButton onSubmitMicrosoft={onSubmitMicrosoft} />}
            {company && (
              <Button sx={{ marginTop: '10px', width: '100%' }} onClick={onSubmitMicrosoft}>
                {t('continue')}
              </Button>
            )}
          </>
        )}
      </Box>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
