import { get, put, post, del } from './request';

class Users {
  static getMe() {
    return get('/users/me').then((res) => res.data);
  }

  static getMeCalCom() {
    return get('/users/me/cal-com').then((res) => res.data);
  }

  static getMeNextSessions(queryParams) {
    return get('/users/me/next_sessions', queryParams).then((res) => res.data);
  }

  static listWithParams(queryParams) {
    return get('/users', queryParams).then((res) => res.data);
  }

  static createCoachee(data) {
    return post('/users', data).then((res) => res.data);
  }

  static updateMe(data) {
    return put('/users/me', data).then((res) => res.data);
  }

  static updateMePassword(payload) {
    return put('/users/me/password', payload).then((res) => res.data);
  }

  static upload(data) {
    return post('/users/upload', data).then((res) => res.data);
  }

  static uploadpublic(data) {
    return post('/upload', data).then((res) => res.data);
  }

  static create(data) {
    return post('/users', data).then((res) => res.data);
  }

  static createRH(data) {
    return post('/users/rh', data).then((res) => res.data);
  }

  static createRHManager(data) {
    return post('/users/rhManager', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/users/${id}`, data).then((res) => res.data);
  }

  static delete(id) {
    return del(`/users/${id}`).then((res) => res.data);
  }

  static getDetail(id) {
    return get(`/users/${id}`).then((res) => res.data);
  }

  static createPassword({ token, newPassword }) {
    return put('/users/me/password', { newPassword }, {}, token).then((res) => res.data);
  }

  static tokenMicrosoftAuth(token) {
    return post('/users/me/tokenMicrosoftAuth', {}, {}, {}, token).then((res) => res.data);
  }

  static tokenSamlAuth(token) {
    return post('/users/me/tokenSamlAuth', {}, {}, {}, token).then((res) => res.data);
  }

  static logout() {
    return get('/users/me/logout');
  }

  static calAccessToken(queryParams) {
    return get('/users/cal/access-token', queryParams);
  }
}

export default Users;
