import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';
import { useTranslation } from 'react-i18next';
import MentoringLine from './components/MentoringLine/MentoringLine';
import CoachViewModalWithRequest from 'ui/components/shared/coaches/CoachViewModalWithRequest';
import MenteeDetailModalWithRequest from 'ui/components/shared/MenteeDetailModal/MenteeDetailModalWithRequest';
import Button from 'ui/components/Button/Button';
import { useNavigate } from 'react-router-dom';

import styles from './MentoringCard.styles';
import { roleList } from 'utils/constUtils';
import { NewChip } from 'ui/components/NewChip/NewChip';
import { isMentoringActionNeeded } from 'utils/mentoring';

const mentoringEmptySvg = `${process.env.PUBLIC_URL}/uploads/images/mentoring-empty.svg`;

const MentoringCard = ({ mentoringList, user, isLoading = false }) => {
  const { t } = useTranslation();
  const [coachIdViewInModal, setCoachIdViewInModal] = useState(null);
  const [coacheeIdViewInModal, setCoacheeIdViewInModal] = useState(null);
  const navigate = useNavigate();

  const height = '394px';

  const sessionLabel = user?.company?.sessionLabel;

  const onClickShowProfile = ({ type, id }) => {
    if (type === roleList.COACH) {
      setCoachIdViewInModal(id);
    } else if (type === roleList.COACHEE) {
      setCoacheeIdViewInModal(id);
    }
  };

  const onClickViewAll = () => {
    navigate('/programs');
  };

  if (!mentoringList?.length) {
    return (
      <EmptyStateCard
        description={t('mentoring_card.empty_state.description', { sessionLabel })}
        height={height}
        isLoading={isLoading}
        src={mentoringEmptySvg}
        subtitle={t('mentoring_card.empty_state.subtitle', { sessionLabel })}
        title={t('mentoring_card.empty_state.title', { sessionLabel })}
      />
    );
  }

  const countProgramWithActionNeeded = mentoringList.filter((mentoring) => isMentoringActionNeeded(mentoring)).length;

  const titleContent = () => {
    if (countProgramWithActionNeeded) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NewChip />
          <Typography sx={styles.countProgramWithActionNeeded}>{countProgramWithActionNeeded}</Typography>
          <Typography sx={styles.textProgramWithActionNeeded}>
            {t('mentoring_card.new_program', { count: countProgramWithActionNeeded, sessionLabel })}
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={styles.title}>
          {t('mentoring_card.default.title', { sessionLabel: user?.company?.sessionLabel })}
        </Typography>

        <Button
          greyWithHover
          sx={{ paddingLeft: '6px', paddingRight: '6px' }}
          variant="tertiary"
          onClick={onClickViewAll}>
          {t('mentoring_card.button_view_all', { sessionLabel: user?.company?.sessionLabel })}
        </Button>
      </Box>
    );
  };

  return (
    <DashboardCard>
      <Box sx={{ height, overflow: 'hidden' }}>
        <Box sx={styles.titleContainer}>{titleContent()}</Box>
        {mentoringList.map((line) => {
          return (
            <MentoringLine
              key={line.id}
              mentoring={line}
              showUserType={user?.role === roleList.COACH ? roleList.COACHEE : roleList.COACH}
              onClickShowProfile={onClickShowProfile}
            />
          );
        })}
      </Box>

      {!!coachIdViewInModal && (
        <CoachViewModalWithRequest
          user={user}
          userRole={user.role}
          coachId={coachIdViewInModal}
          open={!!coachIdViewInModal}
          onClose={() => {
            setCoachIdViewInModal(false);
          }}
          onClickShowProfile={onClickShowProfile}
        />
      )}

      {!!coacheeIdViewInModal && (
        <MenteeDetailModalWithRequest
          user={user}
          userId={coacheeIdViewInModal}
          open={!!coacheeIdViewInModal}
          onClose={() => {
            setCoacheeIdViewInModal(false);
          }}
        />
      )}
    </DashboardCard>
  );
};

export default MentoringCard;
