import { primaryMain } from 'theme';

const styles = {
  container: {
    borderRadius: '8px',
    padding: '5px 10px',
  },
  containerGoBack: {
    paddingTop: '3px',
    paddingRight: '24px',
  },
  containerItemMedium: {
    display: 'flex',
    flex: 1.4,
    flexDirection: 'column',
    border: '0px dashed red',
    borderRadius: '8px',
    padding: '12px 32px 8px 32px',
  },
  containerActions: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  programInformation: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    paddingRight: '32px',
    borderRight: '1px solid',
    borderColor: 'primary.border',
  },
  programInformationDetail: {
    display: 'flex',
    alignItems: 'center',
  },
  statusChip: {
    height: '26px',
    marginRight: '8px',
  },
  startedAt: {
    fontSize: '16px',
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  containerRhContact: {
    alignItems: 'center',
    marginTop: '8px',
    display: 'flex',
    width: 'max-content',
    '&:hover': {
      color: 'primary.main',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  contactManager: {
    fontSize: '14px',
    fontWeight: 300,
    marginLeft: '4px',
  },
  containerUser: {
    display: 'flex',
    flex: 1,
  },
  containerUserClickable: {
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    padding: '4px',
    borderRadius: '6px',

    ':hover': {
      background: `${primaryMain}0D`,
    },
  },
  containerUserInformations: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: '12px',
  },
  avatar: {
    width: 40,
    height: 40,
  },
  title: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.67)',
    fontFamily: 'Karla',
    fontWeight: 400,
    lineHeight: '16.37px',
    wordWrap: 'break-word',
    paddingBottom: '4px',
  },
  topicName: {
    fontSize: '20px',
    fontFamily: 'Karla',
    fontWeight: 600,
    marginBottom: '6px',
  },
  userName: {
    fontSize: '16px',
    fontFamily: 'Karla',
    fontWeight: 500,
    paddingRight: '4px',
  },
  userTitle: {
    color: 'rgba(0, 0, 0, 0.67)',
    fontFamily: 'Karla',
    fontWeight: 400,
    fontSize: '14px',
  },
  mailIcon: {
    width: '16px',
    color: 'primary.main',
    padding: '0 4px',
    borderRadius: '6px',
    height: '20px',
    transition: 'all 0.2s ease-out',
    cursor: 'pointer',

    '&:hover': {
      color: 'white',
      backgroundColor: 'primary.main',
    },
  },
  userInformation: {
    alignItems: 'center',
    display: 'flex',
  },
  email: {
    fontSize: '16px',
    fontFamily: 'Karla',
    fontWeight: 300,
    lineHeight: '18.7px',
    display: 'inline-flex',
  },
  copy_icon: {
    fontSize: '12px',
    fontFamily: 'Karla',
    fontWeight: 300,
    lineHeight: '18.7px',
    display: 'inline-flex',
  },
};
export default styles;
