import React, { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';

import styles from './Resources.styles';

import ListResources from './ListResources';

import { useUploadDocumentUpdate } from 'hooks/usecase/useCreateProgramDocument';

import { useGetProgramDocumentsQuery, useListCompanyRessourcesQuery } from 'hooks/queries';
import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from '@mui/material';
import ResourceModal from './ResourceModal';
import ContainerEmpty from 'ui/components/ContainerEmpty/ContainerEmpty';
import { useTranslation } from 'react-i18next';

const Resources = ({ companyId, programId, isRh }) => {
  const { data: documents, isLoadingProgramResources } = isRh
    ? { data: null, isLoading: false }
    : useGetProgramDocumentsQuery(programId);
  const { data: companyResources, isLoading: isLoadingCompanyResources } = useListCompanyRessourcesQuery();
  const { state: stateUpdate, actions: actionsUpdate, formState: formStateUpdate } = useUploadDocumentUpdate(programId);
  const { t } = useTranslation();

  const resources = companyResources
    ? companyResources.reverse().map((res) => {
        return { ...res, origin: 'company' };
      })
    : [];

  if (!isRh && documents && documents.length) {
    resources.push(
      ...documents.reverse().map((res) => {
        return { ...res, origin: 'program' };
      })
    );
  }

  const loading = isLoadingCompanyResources || (!!programId && isLoadingProgramResources);

  // Loader
  if (loading)
    return (
      <Stack spacing={1} sx={{ padding: '10px', mb: '20px' }}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        {/* For other variants, adjust the size with `width` and `height` */}
        <Grid spacing={1} container>
          {[...Array(5)].map((item, index) => (
            <Grid key={index} item>
              <Stack spacing={0.5}>
                <Skeleton variant="rectangular" width={190} height={100} />
                <Skeleton variant="rounded" width={190} height={70} />
              </Stack>
            </Grid>
          ))}
        </Grid>
        {/* For variant="text", adjust the height via font-size */}
        {programId && <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
        {/* For other variants, adjust the size with `width` and `height` */}
        {programId && (
          <Grid spacing={1} container>
            {[...Array(5)].map((item, index) => (
              <Grid key={index} item>
                <Stack spacing={0.5}>
                  <Skeleton variant="rectangular" width={190} height={100} />
                  <Skeleton variant="rounded" width={190} height={70} />
                </Stack>
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    );

  return (
    <Stack sx={styles.container} container spacing={1}>
      {!!resources.length && (
        <ListResources items={resources} onUpdate={(resource) => actionsUpdate.clickUpdate(resource)} />
      )}

      {resources.length === 0 && (
        <Box sx={{ height: '400px' }}>
          <ContainerEmpty message={t('resources_empty')} />
        </Box>
      )}

      <ResourceModal
        companyId={companyId}
        programId={programId}
        actions={actionsUpdate}
        state={stateUpdate}
        open={stateUpdate.nav.showEditDialog}
        onSubmit={actionsUpdate.edit}
        onClose={actionsUpdate.close}
        loading={formStateUpdate.isSubmitting}
      />
    </Stack>
  );
};

export default Resources;
