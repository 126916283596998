const styles = {
  container: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linearProgress: {
    borderRadius: 5,
    height: 8,
  },
  linearProgressContent: {
    color: '#344054',
    marginLeft: '12px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    fontWeight: '500',
  },
  connectMyCalendarButton: {
    padding: '6px 4px',
  },
  avatar: {
    width: '60px',
    height: '60px',
    border: '3px solid white',
    boxShadow: 'rgba(50, 50, 93, 0.17) 0px 6px 12px -2px, rgba(0, 0, 0, 0.2) 0px 3px 7px -3px;',
  },
  userName: {
    fontWeight: 500,
    fontSize: '16px',
  },
  userInfoContainer: {
    width: '100%',
    display: 'flex',
    gap: '12px',
  },
  userTextualInfoContainer: {
    padding: '4px',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  availabilityButton: {
    padding: '2px 6px',
    height: '26px',
    color: 'primary.grey',
  },
};

export default styles;
