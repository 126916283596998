import React from 'react';

import styles from './DashboardCard.styles';

import { Box } from '@mui/material';

const DashboardCard = ({ children, isShowBoxShadow = false, isActionNeeded = false }) => {
  let containerStyle = !isActionNeeded ? styles.container : { ...styles.container, ...styles.actionNeededContainer };

  if (isShowBoxShadow) {
    containerStyle = { ...containerStyle, ...styles.boxShadowContainer };
  }

  return <Box sx={containerStyle}>{children}</Box>;
};

export default DashboardCard;
