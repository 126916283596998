import React from 'react';
import ProgramsRh from '../programs/ProgramsRh';
import HomeUser from './home-user/HomeUser';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import { useMeQuery } from 'hooks/queries';
import HomeAdmin from './admin/HomeAdmin';
import { roleList } from 'utils/constUtils';

const Home = ({ showExerciseEditor }) => {
  const { data: user } = useMeQuery();

  return (
    <Switch value={user.role}>
      <Case validate={(value) => [roleList.RH, roleList.RH_MANAGER].includes(value)}>
        <ProgramsRh user={user} />
      </Case>
      <Case value={roleList.COACH}>
        <HomeUser user={user} />
      </Case>
      <Case value={roleList.COACHEE}>
        <HomeUser user={user} />
      </Case>
      <Case value={roleList.ADMIN}>
        <HomeAdmin showExerciseEditor={showExerciseEditor} user={user} />
      </Case>
    </Switch>
  );
};

export default Home;
