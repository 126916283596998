import React, { useMemo } from 'react';
import { useListScopeQuery } from 'hooks/queries';
import { useTranslation } from 'react-i18next';
import { ALL } from 'litterals';

const useScopeList = (options) => {
  const { data: scopeList, isLoading } = useListScopeQuery({
    ...options,
  });

  const { t } = useTranslation();

  const scopeOption = useMemo(() => {
    if (!scopeList) return [];

    const formattedScopes = scopeList.map((scope) => ({
      value: scope.id,
      label: scope.name,
    }));

    // Ajoutez simplement l'élément supplémentaire à la fin de la liste
    return formattedScopes.concat({ value: '', label: t(ALL) });
  }, [scopeList, t]);
  const simpleScopeOption = useMemo(
    () =>
      scopeList?.map((scope) => {
        return {
          value: scope.id,
          label: scope.name,
        };
      }) || [],
    [scopeList]
  );

  return {
    scopeOption,
    simpleScopeOption,
    isLoading,
  };
};

export default useScopeList;
