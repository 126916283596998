import React from 'react';
import UserView from '../UserView/UserView';

const CoachView = ({
  coach = {},
  onClickPreviousCoach,
  onClickNextCoach,
  company = {},
  userRole = '',
  isLoading = false,
  directionalArrowsActivated = false,
  availabilitiesEditable = false,
  availabilitiesDisabled = false,
  editButtonDisplay = false,
  onlyFullAvailabilities = false,
  redBorderNoAvailabilities = false,
  scrollToAvailabilities = false,
  subtitleAvailabilities = '',
  showMaxCapacity = true,
  onSelectAvailability,
  isCurrentUser = false,
}) => {
  return (
    <UserView
      user={coach}
      onClickPreviousUser={onClickPreviousCoach}
      onClickNextUser={onClickNextCoach}
      scrollToAvailabilities={scrollToAvailabilities}
      company={company}
      userRole={userRole}
      isLoading={isLoading}
      directionalArrowsActivated={directionalArrowsActivated}
      availabilitiesEditable={availabilitiesEditable}
      availabilitiesDisabled={availabilitiesDisabled}
      editButtonDisplay={editButtonDisplay}
      redBorderNoAvailabilities={redBorderNoAvailabilities}
      subtitleAvailabilities={subtitleAvailabilities}
      showMaxCapacity={showMaxCapacity}
      typeView="coach"
      onlyFullAvailabilities={onlyFullAvailabilities}
      onSelectAvailability={onSelectAvailability}
      isCurrentUser={isCurrentUser}
    />
  );
};

export default CoachView;
