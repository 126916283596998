import React, { useState } from 'react';
import { useMeQuery, useMeCalComQuery, useListProgramsQueryWithParams, useMeNextSessionQuery } from 'hooks/queries';
import { Box, Stack, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './HomeUser.styles';
import RolesDropdown from 'ui/components/RolesDropdown/RolesDropdown';
import ProfileCompletionCard from '../components/ProfileCompletionCard/ProfileCompletionCard';
import Resources from 'ui/pages/program/components/Resources/Resources.jsx';
import { roleList } from 'utils/constUtils';
import MentoringCard from '../components/MentoringCard/MentoringCard';
import NextSessionCard from '../components/NextSessionCard/NextSessionCard';
import HighlightedResourceCard from '../components/HighlightedResourceCard/HighlightedResourceCard';

const HomeUser = () => {
  const { data: user } = useMeQuery();
  const { t } = useTranslation();

  const { data: userCalCom, isLoading: isLoadingCalendarConnected } = useMeCalComQuery({
    enabled: !!user.alreadyCalComInteraction || false,
  });

  const isRh = user.role === roleList.RH || user.role === roleList.RH_MANAGER;

  const [queryParams] = useState({
    page: 1,
    size: 5,
    status: ['done', 'draft', 'pending', 'in progress'],
  });

  const { data, isLoading: isLoadingPrograms } = useListProgramsQueryWithParams(queryParams);
  const {
    data: sessions,
    isLoading: isLoadingSessions,
    refetch: refetchSessions,
  } = useMeNextSessionQuery({
    size: 4,
    page: 1,
    programStatus: ['in progress'],
  });

  const hasEvergreenProgramme = false;

  const onRefetchSessionList = () => {
    refetchSessions();
  };

  return (
    <Stack sx={styles.container}>
      <Box sx={{ display: 'flex', gap: '24px' }}>
        <Typography sx={styles.welcomeTitle}>{t('home_page.user.title', { userName: user.firstname })}</Typography>

        <RolesDropdown user={user} />
      </Box>

      <Grid container spacing={4}>
        <Grid item md={12} sm={12} xs={12} lg={6}>
          <ProfileCompletionCard
            user={user}
            userCalCom={userCalCom}
            isLoadingCalendarConnected={!!user.alreadyCalComInteraction && isLoadingCalendarConnected}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={6}>
          <HighlightedResourceCard company={user.company} role={user.role} />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={hasEvergreenProgramme ? 7 : 5}>
          <MentoringCard user={user} mentoringList={data?.rows || []} isLoading={isLoadingPrograms} />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={hasEvergreenProgramme ? 5 : 7}>
          <NextSessionCard
            sessionList={sessions}
            isLoading={isLoadingSessions}
            isMinifiedVersion={hasEvergreenProgramme}
            company={user.company}
            user={user}
            onRefetchSessionList={onRefetchSessionList}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={12}>
          <Typography sx={styles.resourcesTitle}>{t('company_resources')}</Typography>

          <Resources isRh={isRh} companyId={user.companyId} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default HomeUser;
