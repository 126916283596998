import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';
import Button from 'ui/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { getUrlEvergreenLink, getUrlSelfRegistrationLink } from 'utils/company';
import { useListFormatThemeQuery } from 'hooks/queries';
import { Roles } from 'utils/roles';
import SelfRegistrationLinkModal from 'ui/pages/users/components/SelfRegistrationLinkModal/SelfRegistrationLinkModal';
import NextSessionLine from './components/NextSessionLine';
import AddSessionCalendarDialogWithRequest from 'ui/pages/program/components/Dialog/AddSessionCalendarDialog/AddSessionCalendarDialogWithRequest';
import CoachViewModalWithRequest from 'ui/components/shared/coaches/CoachViewModalWithRequest';
import MenteeDetailModalWithRequest from 'ui/components/shared/MenteeDetailModal/MenteeDetailModalWithRequest';
import { roleList } from 'utils/constUtils';

import styles from './NextSessionCard.styles';

const nextSessionEmptySvg = `${process.env.PUBLIC_URL}/uploads/images/next-session-empty.svg`;

const NextSessionCard = ({ sessionList, user, company, isLoading, isMinifiedVersion, onRefetchSessionList }) => {
  const { t } = useTranslation();
  const [sessionOpenPlannedModal, setSessionOpenPlannedModal] = useState(null);
  const [isOpenSelfRegistration, setIsOpenSelfRegistration] = useState(false);
  const [coachIdViewInModal, setCoachIdViewInModal] = useState(null);
  const [coacheeIdViewInModal, setCoacheeIdViewInModal] = useState(null);

  const { data: listThemeData } = useListFormatThemeQuery(
    company.id,
    { active: true },
    { enabled: company.selfRegistrationActivated }
  );

  const height = '394px';

  const sessionLabel = company?.sessionLabel;

  const onClickEmptyState = () => {
    if (!company.evergreenActivated) {
      if (listThemeData.length === 1) {
        window.open(
          getUrlSelfRegistrationLink(company.selfRegistrationLink, company.applicationLink, listThemeData[0].id),
          '_blank'
        );
      } else {
        setIsOpenSelfRegistration(true);
      }
    } else {
      window.open(getUrlEvergreenLink(company.evergreenLink), '_blank');
    }
  };

  const emptyStateButton = () => {
    if (user.role === Roles.COACHEE && (company.evergreenActivated || company.selfRegistrationActivated)) {
      const buttonType = company.evergreenActivated ? 'evergreen' : 'open_mentoring';

      return (
        <Button isGrey variant="secondary" sx={styles.emptyStateButton} onClick={onClickEmptyState}>
          {t(`next_session_card.empty_state.button.${buttonType}`)}
        </Button>
      );
    }
  };

  const onClickUnplannedButton = (e, session) => {
    e.stopPropagation();
    setSessionOpenPlannedModal(session);
  };

  const onClickShowProfile = ({ type, id }) => {
    if (type === roleList.COACH) {
      setCoachIdViewInModal(id);
    } else if (type === roleList.COACHEE) {
      setCoacheeIdViewInModal(id);
    }
  };

  if (!sessionList?.length || isLoading) {
    return (
      <>
        <EmptyStateCard
          description={t('next_session_card.empty_state.description', { sessionLabel })}
          height={height}
          src={nextSessionEmptySvg}
          subtitle={t('next_session_card.empty_state.subtitle')}
          title={t('next_session_card.empty_state.title')}
          isLoading={isLoading}>
          {emptyStateButton()}
        </EmptyStateCard>

        <SelfRegistrationLinkModal
          title={t('next_session_card.empty_state.self_registration_link_modal.title', { sessionLabel })}
          description={t('next_session_card.empty_state.self_registration_link_modal.description', {
            expertLabel: company.expertLabel,
          })}
          applicationLink={company?.applicationLink || ''}
          companyId={user.companyId || ''}
          selfRegistrationLink={company?.selfRegistrationLink || ''}
          open={isOpenSelfRegistration}
          onClose={() => setIsOpenSelfRegistration(false)}
        />
      </>
    );
  }

  return (
    <>
      <DashboardCard isActionNeeded>
        <Box sx={{ ...styles.container, height }}>
          <Typography sx={styles.title}>{t('next_session_card.default.title')}</Typography>

          {sessionList.slice(0, 4).map((session) => {
            return (
              <NextSessionLine
                showTooltipProgress={isMinifiedVersion}
                key={session.id}
                session={session}
                showUserType={user?.role === roleList.COACH ? roleList.COACHEE : roleList.COACH}
                onClickUnplannedButton={(e) => onClickUnplannedButton(e, session)}
                onClickShowProfile={onClickShowProfile}
              />
            );
          })}
        </Box>
      </DashboardCard>

      {!!sessionOpenPlannedModal && (
        <AddSessionCalendarDialogWithRequest
          coachee={sessionOpenPlannedModal.program.coachee}
          isCoachee={user.role === roleList.COACHEE}
          coach={sessionOpenPlannedModal.program.coach}
          open={!!sessionOpenPlannedModal}
          isRh={false}
          selectedSession={sessionOpenPlannedModal}
          expertLabel={company.expertLabel}
          clientLabel={company.clientLabel}
          setSelectedSession={onRefetchSessionList}
          onClose={() => setSessionOpenPlannedModal(null)}
        />
      )}

      {!!coachIdViewInModal && (
        <CoachViewModalWithRequest
          user={user}
          userRole={user.role}
          coachId={coachIdViewInModal}
          open={!!coachIdViewInModal}
          onClose={() => {
            setCoachIdViewInModal(false);
          }}
        />
      )}

      {!!coacheeIdViewInModal && (
        <MenteeDetailModalWithRequest
          user={user}
          userId={coacheeIdViewInModal}
          open={!!coacheeIdViewInModal}
          onClose={() => {
            setCoacheeIdViewInModal(false);
          }}
        />
      )}
    </>
  );
};

export default NextSessionCard;
