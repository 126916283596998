import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './AddSessionCalendarDialog.styles';
import DatePicker from 'ui/components/form/DatePicker';
import DurationInput from 'ui/components/form/DurationInput';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import ViewModal from 'ui/components/shared/ViewModal';
import Button from 'ui/components/Button/Button';
import GoogleCalendarButton from 'ui/components/GoogleCalendarButton/GoogleCalendarButton';
import OutlookCalendarButton from 'ui/components/OutlookCalendarButton/OutlookCalendarButton';
import { convertMinutesToHoursAndMinutes } from 'utils/dateUtils';
import { useCalSessionInfo } from 'hooks/queries';
import { CalProvider, Booker } from '@calcom/atoms';

const FRONT_URL = window.location.href.replace(window.location.pathname, '');

export default function AddSessionCalendarDialog({
  control,
  session,
  exportDate,
  disabled,
  setOpen,
  open,
  isCoachee,
  coach,
  coachee,
  expertLabel,
  clientLabel,
  exportDuration,
  handleDateChange,
  handleDurationChange,
  handleCalBookingSuccess,
}) {
  const [hours, setHours] = useState(1);
  const [minutes, setMinutes] = useState(0);

  const { data, isLoading: isLoadingCalInfo } = useCalSessionInfo(session.id, { enabled: open, retry: false });

  const handleClose = () => {
    setHours(1);
    setMinutes(0);
    setOpen(false);
  };

  const { t, i18n } = useTranslation();

  const googleDescription = `${t('event_calendar_description')} <a href="${FRONT_URL}">${FRONT_URL.replace(
    `${window.location.protocol}//`,
    ''
  )}</a>`;

  const outlookDescription = `${t('event_calendar_description')} ${FRONT_URL}`;

  useEffect(() => {
    if (session && session.duration !== 60) {
      const { hours, minutes } = convertMinutesToHoursAndMinutes(session.duration);
      setHours(hours);
      setMinutes(minutes);
      exportDuration(parseInt(hours) * 60 + parseInt(minutes));
    }
  }, [session.duration]);

  useEffect(() => {
    if (open) {
      if (session) {
        const { hours, minutes } = convertMinutesToHoursAndMinutes(session.duration);
        setHours(hours);
        setMinutes(minutes);
      }
    }
  }, [open]);

  const {
    field: { value: dateValue },
  } = useController({ control, name: 'date', defaultValue: null });

  const emailInvitation = isCoachee ? coach?.user?.email : coachee?.email;

  const calEventSlug = data?.eventSlug;
  const calUsername = data?.username;
  const bookingUid = data?.bookingUid;

  const isCal = calEventSlug && calUsername;

  const emailToRequest = isCoachee ? coachee?.email : coach?.user?.email;
  const nameToRequest = isCoachee
    ? `${coachee?.firstname} ${coachee?.lastname}`
    : `${coach?.user?.firstname} ${coach?.user?.lastname}`;

  const language = i18n.language ? i18n.language.split('-')[0] : 'fr';

  const containerStyle = isCal ? styles.containerCal : styles.container;

  const modalTitle = isCal
    ? t(`cal_booking.title.${isCoachee ? 'coach' : 'coachee'}`, { expertLabel, clientLabel })
    : t('add_session_to_your_calendar');

  return (
    <div>
      <ViewModal sx={{ outline: 'none' }} open={open} onClose={handleClose} customStyleBox={containerStyle}>
        {!isLoadingCalInfo && (
          <>
            <Box sx={styles.containerTitle}>
              <Typography sx={styles.title}>{modalTitle}</Typography>
            </Box>
            {isCal && (
              <Box sx={{ 'h1[data-testid="event-title"]': { display: 'none' } }}>
                <CalProvider
                  language={language}
                  clientId={process.env.REACT_APP_CAL_OAUTH_CLIENT_ID ?? ''}
                  options={{
                    apiUrl: process.env.REACT_APP_CAL_API_URL ?? '',
                    refreshUrl: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CAL_REFRESH_URL}` ?? '',
                  }}>
                  <Booker
                    rescheduleUid={bookingUid}
                    hideBranding
                    view="MONTH_VIEW" // MONTH_VIEW, WEEK_VIEW, COLUMN_VIEW
                    eventSlug={calEventSlug}
                    username={calUsername}
                    onCreateBookingSuccess={(element) => {
                      handleCalBookingSuccess(element);
                      handleClose();
                    }}
                    defaultFormValues={{
                      name: nameToRequest,
                      email: emailToRequest,
                    }}
                  />
                </CalProvider>
              </Box>
            )}
            {!isCal && (
              <>
                <Box sx={styles.row}>
                  <Box sx={styles.col}>
                    <Box>
                      <DatePicker
                        disabled={disabled}
                        exportDate={exportDate}
                        control={control}
                        name="date"
                        handleDateChange={handleDateChange}
                        ampm={false}
                      />
                    </Box>
                  </Box>
                  <Box sx={styles.col}>
                    <Box>
                      <Box sx={{ paddingLeft: '10px' }}>
                        <DurationInput
                          control={control}
                          hours={hours}
                          setHours={setHours}
                          minutes={minutes}
                          setMinutes={setMinutes}
                          handleDurationChange={handleDurationChange}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 400,
                          paddingTop: '7px',
                          color: '#00000099',
                          marginLeft: '10px',
                        }}>
                        {t('recommended_1h_to_1_30')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <div style={styles.spacerVertical} />
                <Box sx={styles.row}>
                  <OutlookCalendarButton
                    disabled={dateValue.invalid || !dateValue}
                    date={session.date}
                    description={outlookDescription}
                    minuteDuration={session.duration}
                    email={emailInvitation}
                    subject={session.name}
                  />
                  <Box sx={{ width: '28.27px' }} />

                  <GoogleCalendarButton
                    disabled={dateValue.invalid || !dateValue}
                    date={session.date}
                    description={googleDescription}
                    minuteDuration={session.duration}
                    email={emailInvitation}
                    subject={session.name}
                  />
                </Box>
                <Box sx={styles.bottomContainer}>
                  <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                  </Button>
                  <Button sx={styles.validateButton} variant="primary" onClick={handleClose}>
                    {t('validate')}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
        {isLoadingCalInfo && <Box>Loading...</Box>}
      </ViewModal>
    </div>
  );
}
