import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import i18next from 'i18next';

// Convert an array [yyyy, MM, dd, hh, mm] to ISO 8601 date in UTC.
function convertArrayToDate(temps) {
  const [yyyy, MM, dd, hh, mm] = temps;
  // Create a new Date instance specifying the UTC timezone
  const date = new Date(Date.UTC(yyyy, MM - 1, dd, hh, mm));

  // Format the date to ISO 8601
  const dateISO = date.toISOString();

  return dateISO;
}

// Convert a UTC ISO date to a local date in a specific format.
function convertToLocaleDate(utcISODate) {
  const utcDateTime = DateTime.fromISO(utcISODate, { zone: 'utc' });
  const dateLocale = utcDateTime.toLocal();

  const isoDateLocale = dateLocale.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS");

  return isoDateLocale;
}

// Convert an ISO date to an array containing [yyyy, MM, dd, hh, mm] in the local timezone.
function convertToTable(isoDate) {
  const dateLocale = DateTime.fromISO(isoDate);

  // Get date components in the local timezone
  const year = dateLocale.year;
  const month = dateLocale.month;
  const day = dateLocale.day;
  const hour = dateLocale.hour;
  const minute = dateLocale.minute;

  return [year, month, day, hour, minute];
}

// Adjust a date in an array with a specific timezone.
function adjustDateWithTimezone(dateArray) {
  const isoDateLocale = convertToLocaleDate(convertArrayToDate(dateArray));
  const arrayDate = convertToTable(isoDateLocale);

  return arrayDate;
}

const datesDiff = (a, b) => {
  a = a.getTime();
  b = (b || new Date()).getTime();
  const c = a > b ? a : b,
    d = a > b ? b : a;
  return Math.abs(Math.ceil((c - d) / 86400000));
};

const sortByDate = (a, b) => {
  if (!a.date && !b.date) {
    return 0;
  } else if (!a.date) {
    return 1;
  } else if (!b.date) {
    return -1;
  } else {
    return new Date(a.date) - new Date(b.date);
  }
};

const convertMinutesToHoursAndMinutes = (totalMinutes) => {
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;
  return { hours: hours, minutes: minutes };
};

const formatDateGoogleAgenda = (date) => {
  return date.replaceAll(/:|\.|-/g, '');
};

const formatDateWithSecondes = (date) => {
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format('DD/MM/YY HH:mm:ss');
  }
};

const smallDateWithTime = (date) => {
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format('DD MMM - HH:mm');
  }
};

const smallDate = (date) => {
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format('DD MMM');
  }
};

const smallTime = (date) => {
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format('HH:mm');
  }
};

export {
  smallDate,
  smallDateWithTime,
  smallTime,
  adjustDateWithTimezone,
  datesDiff,
  sortByDate,
  convertMinutesToHoursAndMinutes,
  formatDateGoogleAgenda,
  formatDateWithSecondes,
};
