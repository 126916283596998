import React from 'react';
import { useCoachQuery, useMeQuery } from 'hooks/queries';
import CoachViewModal from 'ui/components/shared/coaches/CoachViewModal';

const CoachViewModalWithRequest = ({ coachId, user, ...props }) => {
  const { data: userFetched } = useMeQuery({ enabled: !user });
  const { data: coach, isLoading } = useCoachQuery(coachId);

  const userData = user || userFetched;

  return (
    <CoachViewModal
      userRole={userData.role}
      coach={coach}
      company={userData.company}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default CoachViewModalWithRequest;
