const styles = {
  emptyStateContainer: {
    boxSizing: 'border-box',
    padding: '24px',
  },
  emptyStateTitle: {
    fontSize: '16px',
    fontWeight: 600,
  },
  emptyStateContent: {
    padding: '24px',
    boxSizing: 'border-box',
    height: 'calc(100% - 24px)',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  emptyStateSubtitle: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '6px',
  },
  emptyStateImage: {
    marginBottom: '12px',
  },
  emptyStateDescription: {
    color: 'primary.grey',
    maxWidth: '350px',
  },
};

export default styles;
