import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { roleList } from 'utils/constUtils';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MentoringStatusChip } from 'ui/components/MentoringStatusChip/MentoringStatusChip';

import styles from './MentoringLine.styles';
import { parseJsonTranslate } from 'utils/parse';
import { isMentoringActionNeeded } from 'utils/mentoring';

const MentoringLine = ({ mentoring, onClickShowProfile, showUserType = roleList.COACH }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const showUser =
    showUserType === roleList.COACH
      ? { ...(mentoring?.coach || {}), ...(mentoring?.coach?.user || {}) }
      : mentoring.coachee || mentoring.guest;

  const isActionNeeded = isMentoringActionNeeded(mentoring);

  const onClick = () => {
    if (mentoring.status === 'pending') {
      navigate(`/programs`);
    } else {
      navigate(`/program/${mentoring.id}`);
    }
  };

  const onClickProfile = (event) => {
    if (showUserType === roleList.COACH && mentoring?.coach?.id) {
      onClickShowProfile({ type: roleList.COACH, id: mentoring?.coach?.id });
      event.stopPropagation();
    } else if (showUserType === roleList.COACHEE && mentoring?.coachee?.id) {
      onClickShowProfile({ type: roleList.COACHEE, id: mentoring?.coachee?.id });
      event.stopPropagation();
    }
  };

  const containerStyle = isActionNeeded ? { ...styles.container, ...styles.containerActionNeeded } : styles.container;

  return (
    <Box sx={containerStyle} onClick={onClick}>
      <Box sx={{ flex: 1 }}>
        <Box sx={styles.contentUserClickable} onClick={onClickProfile}>
          <Avatar src={showUser?.picture} sx={styles.avatar} />
          <Box sx={styles.contentName}>
            <Typography sx={styles.userName}>{`${showUser.firstname || ''} ${showUser.lastname || ''}`}</Typography>
            <Typography sx={styles.mentoringTitle}>{parseJsonTranslate(mentoring.theme.name)}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.contentStatus}>
        <MentoringStatusChip status={mentoring.status} />
      </Box>

      <ArrowForwardIosIcon sx={styles.redirectionIcon} />
    </Box>
  );
};

export default MentoringLine;
