import { red } from '@mui/material/colors';

const styles = {
  button: {
    width: 'max-content',
    textWrap: 'nowrap',
    paddingLeft: '14px',
    paddingRight: '14px',
    minWidth: {
      xl: '161px',
      xs: '151px',
    },
    color: 'white',
    fontWeight: 500,
    fontFamily: 'Karla',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '> .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
  greyWithHover: {
    color: 'primary.grey',
    borderColor: 'primary.grey',

    '&:hover': {
      color: 'primary.main',
    },
  },
  primary: {
    backgroundColor: 'primary.main',
  },
  secondary: {
    color: 'primary.main',
  },
  tertiary: {
    color: 'primary.main',
  },
  delete: {
    color: red[700],
    borderColor: red[700],
    '&:hover': {
      borderColor: red[900],
      color: red[900],
      backgroundColor: red[50],
    },
  },
  deletePrimary: {
    color: 'white',
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
  xlg: {
    minWidth: {
      xl: '120px',
      xs: '80px',
    },
    height: '35px',
  },
  xs: {
    minWidth: {
      xl: '70px',
      xs: '70px',
    },
    height: '30px',
    fontSize: '12px',
    padding: '6px 12px',
  },
  lg: {
    minWidth: {
      xl: '120px',
      xs: '80px',
    },
    height: '40px',
  },
  content: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  notification: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    width: '7px',
    height: '7px',
    backgroundColor: 'primary.main',
    borderRadius: '50%',
    border: '3px solid white',
  },
  grey: {
    borderColor: '#D0D5DD',
    color: '#475467',
    backgroundColor: 'white',
  },
};
export default styles;
