const sumObjectValues = (obj) => {
  let sum = 0;
  for (let key in obj) {
    if (typeof obj[key] === 'number') {
      sum += obj[key];
    }
  }
  return sum;
};

const computeScore = (formSettingsList, user, isCalendarConnected, mappingFormSettingsToUser, coefficients = {}) => {
  const propertiesNoCompleted = [];

  if (!formSettingsList?.formFields?.length) return { score: 0, propertiesNoCompleted };

  const keysToDelete = formSettingsList.formFields
    .filter(({ isActivated }) => !isActivated)
    .map(({ fieldName }) => fieldName);

  const filteredMapping = Object.keys(mappingFormSettingsToUser)
    .filter((key) => !keysToDelete.includes(key))
    .reduce((acc, key) => {
      acc[key] = mappingFormSettingsToUser[key];
      return acc;
    }, {});

  const userProperties = Object.values(filteredMapping);

  const valueCalendar = isCalendarConnected * (coefficients['calendar'] || 1);
  let validProperties = valueCalendar;

  if (!valueCalendar) propertiesNoCompleted.push('calendar');

  for (const property of userProperties) {
    if (property === 'scope') {
      const value =
        (user?.scope?.name && user.scope.name.toLowerCase() !== 'undefined sector') * (coefficients[property] || 1);
      validProperties += value;

      if (!value) propertiesNoCompleted.push(property);
    } else if (['languages', 'languagesSpoken', 'coachingSkills'].includes(property)) {
      const value = !!user?.[property]?.length * (coefficients[property] || 1);
      validProperties += value;

      if (!value) propertiesNoCompleted.push(property);
    } else {
      const value = !!user[property] * (coefficients[property] || 1);
      validProperties += value;

      if (!value) propertiesNoCompleted.push(property);
    }
  }

  const addCoefficients = sumObjectValues(coefficients);

  // number of properties detected + calendar connection status
  const score = Math.min(
    Math.ceil(
      (validProperties / (userProperties.length + 1 + addCoefficients - Object.keys(coefficients).length)) * 100
    ),
    100
  );

  return { score, propertiesNoCompleted };
};

export const computeCoacheeProfileCompletionRate = (formSettingsList, user, isCalendarConnected) => {
  const mappingFormSettingsToUser = {
    picture: 'picture',
    spokenLanguages: 'languages',
    timeZone: 'timeZone',
    seniorityLevel: 'seniorityLevelId',
    objectives_expertises: 'bio',
    department: 'departmentId',
    sector: 'scope',
  };

  return computeScore(formSettingsList, user, isCalendarConnected, mappingFormSettingsToUser);
};

export const computeCoachProfileCompletionRate = (formSettingsList, user, isCalendarConnected) => {
  const mappingFormSettingsToUser = {
    picture: 'picture',
    targetList: 'coachingSkills',
    maxCapacity: 'maxCapacity',
    spokenLanguages: 'languagesSpoken',
    timeZone: 'timeZone',
    seniorityLevel: 'seniorityLevelId',
    department: 'departmentId',
    sector: 'scope',
  };

  const coefficients = {
    calendar: 2,
  };

  return computeScore(formSettingsList, user, isCalendarConnected, mappingFormSettingsToUser, coefficients);
};
