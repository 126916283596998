import { primaryMain } from 'theme';

const styles = {
  container: {
    borderRadius: '8px',
    background: 'white',
    border: '1px solid',
    borderColor: 'dashboard.border',
  },
  actionNeededContainer: {
    background: '#6C63FF08',
  },
  boxShadowContainer: {
    boxShadow: `${primaryMain}1A 0px 6px 25px 0px`,
    border: 'none',
  },
};
export default styles;
