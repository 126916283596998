import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';
import Button from '@mui/material/Button';
import styles from './EmailCompanyWarningModal.styles';

export default function EmailCompanyWarningModal({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <ViewModal open={open} onClose={onClose} customStyleBox={styles.container}>
      <Box>
        <Box>
          <Typography variant="h1" sx={styles.title}>
            {t('email_company_warning_modal.title')}
          </Typography>
          <Typography variant="h3" sx={styles.subTitle}>
            {t('email_company_warning_modal.subtitle')}
          </Typography>
        </Box>

        <Box sx={styles.containerButtons}>
          <Button
            type="submit"
            onClick={() => {
              onClose();
            }}
            variant="contained">
            {t('email_company_warning_modal.button')}
          </Button>
        </Box>
      </Box>
    </ViewModal>
  );
}
