const styles = {
  title: {
    fontSize: '16px',
    fontWeight: 600,
  },
  titleContainer: {
    borderBottom: '1px solid',
    borderColor: 'dashboard.borderSecondary',
    padding: '20px 24px',
  },
  countProgramWithActionNeeded: {
    color: 'primary.main',
    paddingLeft: '12px',
    fontWeight: 500,
    fontSize: '16px',
    fontStyle: 'italic',
  },
  textProgramWithActionNeeded: {
    paddingLeft: '4px',
    color: '#101828',
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'italic',
  },
};

export default styles;
