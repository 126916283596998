import React from 'react';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import UserChip from 'ui/components/shared/UserChip/UserChip';
import { useTranslation } from 'react-i18next';

import styles from './MentoringAvailableChip.styles';

const MentoringAvailableChip = ({ user, sessionLabel }) => {
  const { t } = useTranslation();

  return (
    <UserChip
      color="black"
      icon={<PeopleAltOutlinedIcon sx={styles.chipIcon} />}
      label={t('mentoring_available', { count: user.maxCapacity - user.programsInProgress, sessionLabel })}
    />
  );
};

export default MentoringAvailableChip;
