/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, { useEffect } from 'react';

// UI Local Lib dependencies
import useSession from 'hooks/usecase/useSessions';
import AddSessionCalendarDialog from '../../Dialog/AddSessionCalendarDialog';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const AddSessionCalendarDialogWithRequest = ({
  open,
  selectedSession = {},
  handleChangeSession,
  setSelectedSession,
  isRh,
  coachee,
  coach,
  isCoachee,
  expertLabel,
  clientLabel,
  onClose,
}) => {
  const { t } = useTranslation();

  /* ********************************** HOOKS ********************************* */

  const { state, actions } = useSession(selectedSession);
  const { setStateAlert } = useAlert();

  /* ********************************  HELPERS ******************************* */

  const handleDateChange = async (value) => {
    await actions.submit({
      ...selectedSession,
      date: value,
    });
    setSelectedSession &&
      setSelectedSession({
        ...selectedSession,
        date: value,
      });
  };

  const handleDurationChange = async (value) => {
    await actions.submit({
      ...selectedSession,
      duration: value,
    });
    setSelectedSession &&
      setSelectedSession({
        ...selectedSession,
        duration: value,
      });
  };

  const handleCalBookingSuccess = async (booking) => {
    if (booking.status === 'success') {
      const startDate = new Date(booking?.data?.startTime);
      const endDate = new Date(booking?.data?.endTime);

      const date = booking?.data?.startTime;
      const duration = (endDate - startDate) / (1000 * 60);

      await actions.submit({
        ...selectedSession,
        date,
        duration,
        calBooking: booking?.data || {},
      });

      setSelectedSession &&
        setSelectedSession({
          ...selectedSession,
          date,
          duration,
        });

      setStateAlert({ open: true, message: t('cal_booking.sucess') });
    } else {
      setStateAlert({ open: true, message: t('cal_booking.error'), type: 'error' });
    }
  };

  return (
    <AddSessionCalendarDialog
      key={selectedSession?.id}
      disabled={isRh}
      exportDate={(date) => handleChangeSession && handleChangeSession(selectedSession.id, { date })}
      exportDuration={(duration) => {
        handleChangeSession && handleChangeSession(selectedSession.id, { duration: duration });
      }}
      control={state.form.control}
      handleDateChange={handleDateChange}
      handleDurationChange={handleDurationChange}
      handleCalBookingSuccess={handleCalBookingSuccess}
      open={open}
      setOpen={onClose}
      session={selectedSession}
      coachee={coachee}
      expertLabel={expertLabel}
      clientLabel={clientLabel}
      isCoachee={isCoachee}
      coach={coach}
    />
  );
};

export default AddSessionCalendarDialogWithRequest;
