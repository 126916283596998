import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

import styles from './NewChip.styles';

export const NewChip = () => {
  const { t } = useTranslation();

  return <Chip label={t('new_chip')} sx={styles.chip} />;
};
