import React, { useRef, useMemo, useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Stack, Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

// Components
import Input from '../components/Input';
import PhoneInput2 from '../components/PhoneInput2';
// eslint-disable-next-line no-unused-vars
import Button from 'ui/components/Button/Button';
// eslint-disable-next-line no-unused-vars
import Select from '../components/Select';
import Skeleton from '@mui/material/Skeleton';
import MultiSelect from 'ui/components/shared/MultiSelect';
import MultiSelectNative from '../components/MultiSelectNative/MultiSelectNative';
import UploadField from '../components/UploadPicture/UploadField';
import Grid from '@mui/material/Grid';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import LanguageSelect from 'ui/components/LanguageSelect/LanguageSelect';

import { randomFileId } from 'utils/uploader';

// Hooks
import styles from './ApplicationForm.styles';
import useFormPublic from 'hooks/usecase/useFormPublic';
import { showIntercomVisitor } from 'utils/intercom';

// Style
import { useApplication } from 'hooks/usecase/useApplication';
import { useTranslation } from 'react-i18next';

// Validation
import createValidation from '../validators/application.validator';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

import {
  ADD_PHOTO,
  APPFORM_TOOLTIP_BIO,
  APPFORM_TOOLTIP_DEPARTMENT,
  APPFORM_TOOLTIP_EXPECTATIONS,
  APPFORM_TOOLTIP_IDEMPLOYEE,
  APPFORM_TOOLTIP_LANGUAGES,
  APPFORM_TOOLTIP_LOCALIZATION,
  APPFORM_TOOLTIP_MAXCAPCITY,
  APPFORM_TOOLTIP_ROLE,
  APPFORM_TOOLTIP_SECTOR,
  APPFORM_TOOLTIP_TITLE,
  APPLICATION_FORM_FIELD_AFFILIATION,
  APPLICATION_FORM_FIELD_CALENDAR_URL,
  APPLICATION_FORM_FIELD_CURRENT_LOCATION,
  APPLICATION_FORM_FIELD_DEPARTMENT,
  APPLICATION_FORM_FIELD_GENDER_IDENTITY,
  APPLICATION_FORM_FIELD_HOURS_OF_EXPERIENCE,
  APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE,
  APPLICATION_FORM_FIELD_SELECT_EXPERTISES,
  APPLICATION_FORM_FIELD_SELECT_NUMBER,
  APPLICATION_FORM_FIELD_SELECT_OBJECTIVES,
  APPLICATION_FORM_FIELD_SELECT_YOUR_ROLE,
  APPLICATION_FORM_FIELD_SENIORITY_LEVEL,
  APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES,
  APPLICATION_FORM_FIELD_TITLE,
  APPLICATION_FORM_FIELD_VIDEO_URL,
  APPLICATION_FORM_MANAGER_EMAIL_LABEL,
  APPLICATION_FORM_TITLE_ACHIEVED_GOALS,
  APPLICATION_FORM_TITLE_BIO,
  APPLICATION_FORM_TITLE_CAPACITY_FOR_CLIENTS,
  APPLICATION_FORM_TITLE_EMPLOYEE_OR_EXTERNAL_EXPERT,
  APPLICATION_FORM_TITLE_EXPECTATIONS_INITIATIVE,
  APPLICATION_FORM_TITLE_EXPECTATIONS_PROGRAM,
  APPLICATION_FORM_TITLE_MATCHING_INFO,
  APPLICATION_FORM_TITLE_PARTICIPATION_IN_PROGRAM,
  APPLICATION_FORM_TITLE_PROGRAM_GOALS,
  APPLICATION_FORM_TITLE_SHARE_YOUR_PORTRAIT_PHOTO,
  APPLY_TO_PROGRAM,
  EMAIL,
  EXPERIENCE_ENUM_1,
  EXPERIENCE_ENUM_2,
  EXPERIENCE_ENUM_3,
  EXPERIENCE_ENUM_4,
  FIELD_EMPLOYEEID,
  FIELD_LINKEDINURL,
  FIRSTNAME,
  IMAGE_UPLOADED_SUCCESS,
  INTERNAL,
  LASTNAME,
  PHONE_NUMBER,
  PLACEHOLDER_MANAGER_EMAIL,
  PROFILE_INFO,
} from 'litterals';

// constants
const PLACEHOLDER_RESOURCE = `${process.env.PUBLIC_URL}/uploads/images/Pathline-logo.svg`;
const APPLICATION_HEADER = `${process.env.PUBLIC_URL}/uploads/images/application-header.svg`;
const SUCCESS_IMAGE = `${process.env.PUBLIC_URL}/uploads/images/success-image.svg`;
const VERIFIED_IMAGE = `${process.env.PUBLIC_URL}/uploads/images/verified-icon.svg`;
// utils
import { capitalizeString } from 'utils/functions';
import InputWithTooltip from 'ui/pages/evergreen-link/components/InputWithTooltip';
const mapFormSetting = (formSettingsList) => {
  let result = [];
  if (formSettingsList) {
    result = Object.fromEntries(
      formSettingsList?.formFields?.map(({ fieldName, isActivated }) => [fieldName, isActivated])
    );
  }
  return result;
};
const ApplicationForm = () => {
  const ref = useRef();
  const refFirstname = useRef();
  const refLastname = useRef();
  const refEmail = useRef();
  const refLinkedinUrl = useRef();
  const refIdNumber = useRef();
  const refLocation = useRef();
  const refScope = useRef();
  const refSeniorityLevel = useRef();
  const refTimeZone = useRef();
  const refGender = useRef();
  const refLanguages = useRef();
  const refDepartment = useRef();

  const refIsMentor = useRef();

  const refIsInternal = useRef();

  const refEmailLeader = useRef();

  const refMaxCapacity = useRef();

  const refCoachingSkills = useRef();

  const refExpectations = useRef();

  const refTitle = useRef();
  const refCalendarLink = useRef();
  const refVideoLink = useRef();
  const refDescriptionExperience = useRef();

  const refTargetList = useRef();

  const inputRef = useRef();

  const { t } = useTranslation();

  const { state, actions } = useApplication(ref);

  const control = state.form.control;
  const expertLabel = state.companyData?.company?.expertLabel;
  const clientLabel = state.companyData?.company?.clientLabel;
  const sessionLabel = state.companyData?.company?.sessionLabel;
  const headerPicture = state.companyData?.company?.customHeaderPicture;
  const VERIFIED_ICON = <Box sx={{ paddingRight: '10px' }} component="img" src={VERIFIED_IMAGE} />;

  showIntercomVisitor();

  const handleTransformListForOptionsSelect = (list) =>
    list.map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const handleTransformListForOptionsSelectTargets = (list) =>
    list.map((item) => ({
      label: item.name,
      value: item.name,
    }));

  const companyLogo = state.companyData?.company?.logo;
  const company = state.companyData?.company;
  // Call createValidation with additional parameters
  const [queryParams, setQueryParams] = useState({ isMentor: false });

  const { formSettingsList, departments, seniorityLevels, timeZones } = useFormPublic(
    company.applicationLink,
    queryParams
  );
  let result = mapFormSetting(formSettingsList);

  if (formSettingsList) {
    result = Object.fromEntries(
      formSettingsList?.formFields?.map(({ fieldName, isActivated }) => [fieldName, isActivated])
    );
  }

  const [validation, setValidation] = useState(createValidation(result));

  useEffect(() => {
    const isMentor = actions.watch('isMentor');
    state.form.clearErrors();
    setValidation(null);
    const updatedParams = {
      isMentor: isMentor,
    };

    setQueryParams(updatedParams);

    // Store the current isMentor value
    const currentIsMentor = isMentor;

    // Reset the form
    state.form.reset();

    // Set the isMentor value back to the form
    state.form.setValue('isMentor', currentIsMentor);
  }, [actions.watch('isMentor')]);

  useEffect(() => {
    setValidation(createValidation(result));
  }, [formSettingsList]);

  /* ********************************** CONSTANTS ********************************* */
  const ROLES_OPTIONS = [
    { id: false, name: clientLabel ? clientLabel : '' },
    { id: true, name: expertLabel ? expertLabel : '' },
  ];

  const EXPERT_TYPE_OPTIONS = [
    { label: capitalizeString(t('internal')), value: 'INTERNAL' },
    { label: capitalizeString(t('external')), value: 'EXTERNAL' },
  ];

  let SENIORITY_LEVELS_OPTIONS = [];
  if (seniorityLevels?.length) {
    SENIORITY_LEVELS_OPTIONS = seniorityLevels.map((level) => {
      return { label: capitalizeString(t(level.name)), value: level.id };
    });
  }
  let DEPARTMENTS_OPTIONS = [];
  if (departments?.length) {
    DEPARTMENTS_OPTIONS = departments.map((department) => {
      return { label: capitalizeString(t(department.name)), value: department.id };
    });
  }

  let TIME_ZONES_OPTIONS = [];
  if (timeZones?.length) {
    TIME_ZONES_OPTIONS = timeZones.map((timeZone) => {
      return { label: `${timeZone.label} (${timeZone.value})`, value: timeZone.value };
    });
  }

  const GENDER_OPTIONS = [
    { label: capitalizeString(t('F')), value: 'F' },
    { label: capitalizeString(t('M')), value: 'M' },
    { label: capitalizeString(t('-')), value: '-' },
  ];

  const HOURS_OF_EXPERIENCE_VALUES = useMemo(() => {
    return [
      { label: capitalizeString(t(EXPERIENCE_ENUM_1)), value: 'Less than 100 hours of practice' },
      { label: capitalizeString(t(EXPERIENCE_ENUM_2)), value: 'ACC - 100 hours of practice' },
      { label: capitalizeString(t(EXPERIENCE_ENUM_3)), value: 'PCC - 500 hours of practice' },
      { label: capitalizeString(t(EXPERIENCE_ENUM_4)), value: 'PCC - 500 hours of practice' },
    ];
  }, [t]);

  const onClick = () => {
    inputRef?.current?.click();
  };
  const matching_info_Condition =
    validation?.location?.required ||
    validation?.scopeId?.required ||
    validation?.seniorityLevel?.required ||
    validation?.gender?.required ||
    validation?.department?.required ||
    validation?.timeZone?.required ||
    validation?.languages?.required;

  const coach_Profile_condition =
    validation?.title?.required ||
    validation?.videoLink?.required ||
    validation?.calendarLink?.required ||
    validation?.hoursOfExperience?.required;

  const phoneRegex = /^\+[1-9]\d{1,2}\s?\d{3,7}$/;

  const validatePhone = (input) => {
    const cleanedInput = input.replace(/[^\d+]/g, '');
    return cleanedInput === '' || phoneRegex.test(cleanedInput);
  };

  return (
    <Stack sx={{ maxHeight: 200, overflow: 'auto', minHeight: '100vh', backgroundColor: 'background.secondary' }}>
      <AppBar sx={styles.navbar} elevation={5}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              maxHeight: '60px',
              maxWidth: '100px',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
            src={companyLogo ? companyLogo : PLACEHOLDER_RESOURCE}
          />
        </Toolbar>
      </AppBar>
      <Stack ref={ref} sx={styles.container} alignItems={'center'}>
        {state.form.isApplied ? (
          <Stack sx={styles.containerSuccess}>
            <Box height={'218px'} width={'387px'} sx={{ maxWidth: '100%' }} component="img" src={SUCCESS_IMAGE} />
            <Typography sx={styles.successTitle}>{t('registration_success1')}</Typography>
            <Typography sx={styles.successDescription}>{t('registration_success2')}</Typography>
            <Stack sx={{ m: 1 }} />
            <Typography sx={styles.successDescription}>
              {VERIFIED_ICON} {t('registration_success3')}
            </Typography>
            <Stack sx={{ m: 0.2 }} />
            <Typography sx={styles.successDescription}>
              {VERIFIED_ICON} {t('registration_success4')}
            </Typography>
            <Stack sx={{ m: 0.2 }} />
            <Typography sx={styles.successDescription}>
              {VERIFIED_ICON} {t('registration_success5')}
            </Typography>
            <Stack sx={{ m: 1 }} />
            <Typography sx={styles.successDescription}>{t('registration_success6')}</Typography>
            <Stack sx={{ m: 1 }} />
            <Typography sx={styles.successDescription}>
              {VERIFIED_ICON} {t('registration_success7')}
            </Typography>
            <Stack sx={{ m: 0.2 }} />
            <Typography sx={styles.successDescription}>
              {VERIFIED_ICON} {t('registration_success8')}
            </Typography>
            <Stack sx={{ m: 0.2 }} />
            <Typography sx={styles.successDescription}>
              {VERIFIED_ICON} {t('registration_success9')}
            </Typography>
            <Stack sx={{ m: 1 }} />
            <Stack alignItems="flex-end">
              <Button
                onClick={() => {
                  window.open(process.env.REACT_APP_PATHLINE_RESSOURCES_LINK, '_blank', 'noopener,noreferrer');
                }}
                variant="primary">
                <Typography sx={styles.successDescription}>{t('go_to_pathline_resources')}</Typography>
              </Button>
            </Stack>
          </Stack>
        ) : (
          validation && (
            <>
              <Stack sx={styles.containerImage}>
                <Box component="img" src={headerPicture ? headerPicture : APPLICATION_HEADER} />
              </Stack>
              <Stack sx={styles.containerCard}>
                <Stack sx={styles.containerBand} />
                <Stack sx={styles.containerTexts}>
                  <Typography sx={styles.title} variant="h5">
                    {t('application_form_title').replace('{{sessionLabel}}', sessionLabel)}
                  </Typography>
                  <Stack sx={{ mb: '10px' }} />
                  <Typography sx={styles.description} variant="h7">
                    {t('registration_welcome_message')}
                  </Typography>
                  <Stack sx={{ m: 1 }} />
                </Stack>
              </Stack>
              {/*********** role ***********/}
              <Stack sx={styles.containerInput}>
                <InputWithTooltip
                  tooltipText={t(APPFORM_TOOLTIP_ROLE)
                    .replace('{{expertLabel}}', expertLabel)
                    .replace('{{expertLabel}}', expertLabel)
                    .replace('{{clientLabel}}', clientLabel)}>
                  <Box sx={styles.spacerLabelTop} />
                  <Typography variant="h7">
                    {capitalizeString(t(APPLICATION_FORM_TITLE_PARTICIPATION_IN_PROGRAM))}
                  </Typography>
                </InputWithTooltip>

                <Box sx={styles.spacerLabelTop} />
                <Select
                  options={handleTransformListForOptionsSelect(ROLES_OPTIONS)}
                  label={capitalizeString(t(APPLICATION_FORM_FIELD_SELECT_YOUR_ROLE)) + '*'}
                  name="isMentor"
                  ref={refIsMentor}
                  control={control}
                  rules={validation?.isMentor}
                />

                <Stack sx={{ m: 1 }} />
              </Stack>

              {actions.watch('isMentor') !== null ? (
                <>
                  {/*********** profil_info **************/}
                  <Stack sx={styles.containerInput}>
                    <Typography variant="h7">{capitalizeString(t(PROFILE_INFO))} </Typography>
                    <Box sx={styles.spacerLabelTop} />

                    <Stack>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
                        <Input
                          label={capitalizeString(t(FIRSTNAME)) + '*'}
                          name="firstname"
                          ref={refFirstname}
                          control={control}
                          rules={validation?.firstname}
                          style={{ width: '46%' }}
                        />
                        <Input
                          label={capitalizeString(t(LASTNAME)) + '*'}
                          name="lastname"
                          ref={refLastname}
                          control={control}
                          rules={validation?.lastname}
                          style={{ width: '46%' }}
                        />
                        <Input
                          ref={refEmail}
                          label={t(EMAIL) + '*'}
                          name="email"
                          control={control}
                          rules={validation?.email}
                          style={{ width: '46%' }}
                        />
                        {validation?.phoneNumber?.required && (
                          <PhoneInput2
                            name="phoneNumber"
                            label={capitalizeString(t(PHONE_NUMBER))}
                            control={control}
                            defaultValue=""
                            style={{ width: '46%' }}
                            rules={{
                              required: false,
                              validate: (value) => {
                                if (!value) {
                                  return true;
                                } else if (validatePhone(value)) {
                                  return t('validate_phone_number');
                                }
                              },
                            }}
                          />
                        )}
                        {validation?.linkedinUrl?.required && (
                          <Input
                            ref={refLinkedinUrl}
                            label={capitalizeString(t(FIELD_LINKEDINURL))}
                            name="linkedinUrl"
                            control={control}
                            style={{ width: '46%' }}
                          />
                        )}
                        {validation?.idNumber?.required && (
                          <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_IDEMPLOYEE)}>
                            <Input
                              label={capitalizeString(t(FIELD_EMPLOYEEID)) + '*'}
                              name="idNumber"
                              ref={refIdNumber}
                              control={control}
                              rules={validation?.idNumber}
                              style={{ width: '100%' }}
                            />
                          </InputWithTooltip>
                        )}
                        <Stack sx={{ width: '46%' }}>
                          <LanguageSelect
                            label={capitalizeString(t('application_form_field_platform_content_language')) + '*'}
                            name="platformContentLanguage"
                            control={control}
                            rules={validation?.platformContentLanguage}
                          />
                        </Stack>
                      </Box>
                    </Stack>
                    <Stack sx={{ m: 1 }} />
                  </Stack>
                  {/*********** matching_info  ***********/}
                  {matching_info_Condition && (
                    <Stack sx={styles.containerInput}>
                      <Typography variant="h7">{capitalizeString(t(APPLICATION_FORM_TITLE_MATCHING_INFO))} </Typography>
                      <Box sx={styles.spacerLabelTop} />
                      <Stack>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
                          {validation?.location?.required && (
                            <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_LOCALIZATION)}>
                              <Input
                                label={capitalizeString(t(APPLICATION_FORM_FIELD_CURRENT_LOCATION)) + '*'}
                                name="location"
                                ref={refLocation}
                                control={control}
                                rules={validation?.location}
                                style={{ width: '100%' }}
                              />
                            </InputWithTooltip>
                          )}
                          {state.scopes.isLoading ? (
                            <Skeleton />
                          ) : (
                            <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_SECTOR)}>
                              <Stack sx={{ width: '100%' }}>
                                {validation?.scopeId?.required && (
                                  <Select
                                    options={handleTransformListForOptionsSelect(state.scopes.list)}
                                    label={capitalizeString(t(APPLICATION_FORM_FIELD_AFFILIATION)) + '*'}
                                    name="scopeId"
                                    ref={refScope}
                                    control={control}
                                    rules={validation?.scopeId}
                                  />
                                )}
                              </Stack>
                            </InputWithTooltip>
                          )}
                          {validation?.seniorityLevel?.required && (
                            <Stack style={{ width: '46%' }}>
                              <Select
                                options={SENIORITY_LEVELS_OPTIONS}
                                label={capitalizeString(t(APPLICATION_FORM_FIELD_SENIORITY_LEVEL)) + '*'}
                                name="seniorityLevelId"
                                ref={refSeniorityLevel}
                                control={control}
                                rules={validation?.seniorityLevel}
                              />
                            </Stack>
                          )}
                          {validation?.gender?.required && (
                            <Stack sx={{ width: '46%' }}>
                              <Select
                                options={GENDER_OPTIONS}
                                label={capitalizeString(t(APPLICATION_FORM_FIELD_GENDER_IDENTITY)) + '*'}
                                name="gender"
                                ref={refGender}
                                control={control}
                                rules={validation?.gender}
                              />
                            </Stack>
                          )}
                          {validation?.languages?.required && (
                            <InputWithTooltip
                              tooltipText={t(APPFORM_TOOLTIP_LANGUAGES).replace('{{sessionLabel}}', sessionLabel)}
                              top="60%">
                              <MultiSelect
                                label={capitalizeString(t(APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES)) + '*'}
                                ref={refLanguages}
                                name="languages"
                                control={control}
                                rules={validation?.languages}
                              />
                            </InputWithTooltip>
                          )}
                          {validation?.department?.required && (
                            <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_DEPARTMENT)}>
                              <Stack sx={{ width: '100%' }}>
                                <Select
                                  options={DEPARTMENTS_OPTIONS}
                                  label={capitalizeString(t(APPLICATION_FORM_FIELD_DEPARTMENT)) + '*'}
                                  name="departmentId"
                                  ref={refDepartment}
                                  control={control}
                                  rules={validation?.department}
                                />
                              </Stack>
                            </InputWithTooltip>
                          )}
                          {validation?.timeZone?.required && (
                            <Stack style={{ width: '46%' }}>
                              <Select
                                options={TIME_ZONES_OPTIONS}
                                label={capitalizeString(t('application_form_field_time_zone')) + '*'}
                                name="timeZone"
                                ref={refTimeZone}
                                control={control}
                                rules={validation?.timeZone}
                              />
                            </Stack>
                          )}
                        </Box>
                      </Stack>
                      <Stack sx={{ m: 1 }} />
                    </Stack>
                  )}

                  {/** ------------------Condition by role to show this bloc----------------- */}
                  {actions.watch('isMentor') === true ? (
                    <Box>
                      {/*********** Picture ***********/}
                      {validation?.picture?.required && (
                        <Stack sx={styles.containerInput}>
                          <Box style={{ display: 'none' }}>
                            <UploadField
                              label={capitalizeString('picture')}
                              name="picture"
                              folder={'users/'}
                              fileId={randomFileId()}
                              control={state.form.control}
                              ref={inputRef}
                              setDisabledButton={() => {}}
                              accept={'picture'}
                              wq
                              submitForm={actions.onUploadPicture}
                              onUploadSuccess={actions.updateImage}
                            />
                          </Box>
                          <Typography variant="h7">
                            {capitalizeString(t(APPLICATION_FORM_TITLE_SHARE_YOUR_PORTRAIT_PHOTO)).replace(
                              '{{expertLabel}}',
                              expertLabel
                            )}
                          </Typography>
                          <Stack sx={{ m: 1 }} />
                          <Grid alignItems={'center'} container spacing={1}>
                            <Grid item>
                              <Box
                                variant="flex"
                                role="button"
                                onClick={onClick}
                                sx={{
                                  cursor: 'pointer',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                  ':hover': { background: '#1407BF14' },
                                }}>
                                <CropOriginalIcon />
                                <Box sx={{ width: '7px' }} />
                                <Typography>{capitalizeString(t(ADD_PHOTO))}</Typography>
                              </Box>
                            </Grid>
                            {actions.watch('picture').length > 0 && (
                              <Grid item>
                                <Box sx={{ display: 'flex' }} size="small" onClick={() => {}}>
                                  <FileDownloadDoneIcon fontSize="small" />
                                  <Box sx={{ width: '10px' }} />
                                  <Typography sx={{}} variant="body2">
                                    {capitalizeString(t(IMAGE_UPLOADED_SUCCESS))}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                      {/*********** {{expertLabel}} Profile Info ***********/}
                      {coach_Profile_condition && (
                        <Stack sx={styles.containerInput}>
                          <Typography variant="h7">{t(`${expertLabel} Profile Info`)}</Typography>
                          <Box sx={styles.spacerLabelTop} />
                          <Stack>
                            <Box
                              sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
                              {validation?.title?.required && (
                                <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_TITLE)}>
                                  <Input
                                    label={capitalizeString(t(APPLICATION_FORM_FIELD_TITLE)) + '*'}
                                    name="title"
                                    ref={refTitle}
                                    control={control}
                                    rules={validation?.title}
                                    style={{ width: '100%' }}
                                  />
                                </InputWithTooltip>
                              )}
                              {validation?.calendarLink?.required && (
                                <Input
                                  label={capitalizeString(t(APPLICATION_FORM_FIELD_CALENDAR_URL))}
                                  name="calendarLink"
                                  ref={refCalendarLink}
                                  control={control}
                                  style={{ width: '46%' }}
                                />
                              )}
                              {validation?.videoLink?.required && (
                                <Input
                                  label={capitalizeString(t(APPLICATION_FORM_FIELD_VIDEO_URL))}
                                  name="videoLink"
                                  ref={refVideoLink}
                                  control={control}
                                  style={{ width: '46%' }}
                                />
                              )}
                              {validation?.hoursOfExperience?.required && (
                                <Stack sx={{ display: 'flex', width: '46%' }}>
                                  <Select
                                    options={HOURS_OF_EXPERIENCE_VALUES}
                                    label={capitalizeString(t(APPLICATION_FORM_FIELD_HOURS_OF_EXPERIENCE))}
                                    name="hoursOfExperience"
                                    ref={refGender}
                                    control={control}
                                  />
                                </Stack>
                              )}
                            </Box>
                          </Stack>
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                      {/** Internal or External */}
                      {validation?.isInternal?.required && (
                        <Stack sx={styles.containerInput}>
                          <Typography variant="h7">
                            {capitalizeString(t(APPLICATION_FORM_TITLE_EMPLOYEE_OR_EXTERNAL_EXPERT, { expertLabel }))}
                          </Typography>
                          <Box sx={styles.spacerLabelTop} />
                          <Select
                            options={EXPERT_TYPE_OPTIONS}
                            label={capitalizeString(t(INTERNAL)) + '/' + capitalizeString(t('external')) + '*'}
                            name="isInternal"
                            ref={refIsInternal}
                            control={control}
                            rules={validation?.isInternal}
                          />
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                      {/*********** Capacity ***********/}
                      {validation?.maxCapacity?.required && (
                        <Stack sx={styles.containerInput}>
                          <InputWithTooltip width="100%" tooltipText={t(APPFORM_TOOLTIP_MAXCAPCITY)}>
                            <Box sx={styles.spacerLabelTop} />
                            <Typography variant="h7">
                              {capitalizeString(
                                t(APPLICATION_FORM_TITLE_CAPACITY_FOR_CLIENTS).replace('{{clientlabel}}', clientLabel)
                              )}
                            </Typography>
                          </InputWithTooltip>
                          <Box sx={styles.spacerLabelTop} />

                          <Input
                            ref={refMaxCapacity}
                            label={capitalizeString(t(APPLICATION_FORM_FIELD_SELECT_NUMBER)) + '*'}
                            name="maxCapacity"
                            control={control}
                            rules={validation?.maxCapacity}
                            inputProps={{ type: 'number' }}
                          />

                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                      {/*********** Expertise ***********/}
                      {validation?.targetList?.required && (
                        <Stack sx={styles.containerInput}>
                          <Typography variant="h7">
                            {capitalizeString(t(APPLICATION_FORM_TITLE_ACHIEVED_GOALS))}
                          </Typography>
                          {state.scopes.isLoading ? (
                            <Skeleton />
                          ) : (
                            <MultiSelectNative
                              options={handleTransformListForOptionsSelectTargets(
                                company && company.target_template_items ? company.target_template_items : []
                              )}
                              ref={refCoachingSkills}
                              name="targetList"
                              label={capitalizeString(t(APPLICATION_FORM_FIELD_SELECT_EXPERTISES)) + '*'}
                              control={control}
                              rules={validation?.targetList}
                            />
                          )}

                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                      {/*********** BIO ***********/}
                      {validation.bio.required && (
                        <Stack sx={styles.containerInput}>
                          <InputWithTooltip
                            width="100%"
                            tooltipText={t(APPFORM_TOOLTIP_BIO).replace('{{expertLabel}}', expertLabel)}>
                            <Box sx={styles.spacerLabelTop} />
                            <Typography variant="h7">{capitalizeString(t(APPLICATION_FORM_TITLE_BIO))}</Typography>
                          </InputWithTooltip>
                          <Stack sx={{ m: 1 }} />
                          <Box sx={{ display: 'flex' }}>
                            {
                              <Input
                                ref={refDescriptionExperience}
                                label={capitalizeString(t(APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE)) + '*'}
                                name="bio"
                                control={control}
                                rows={3}
                                maxRows={4}
                                multiline
                                variant="outlined"
                                rules={validation?.bio}
                              />
                            }
                          </Box>
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}

                      {/*********** Expectations ***********/}
                      {validation?.expectations1?.required && (
                        <Stack sx={styles.containerInput}>
                          <Typography variant="h7">
                            {capitalizeString(t(APPLICATION_FORM_TITLE_EXPECTATIONS_INITIATIVE))}
                          </Typography>
                          <Stack sx={{ m: 1 }} />
                          <Input
                            rows={3}
                            maxRows={4}
                            multiline
                            variant="outlined"
                            ref={refExpectations}
                            label={capitalizeString(t(APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE)) + '*'}
                            name="expectations1"
                            control={control}
                            rules={validation?.expectations1}
                          />
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      {/*********** Picture ***********/}
                      {validation?.picture?.required && (
                        <Stack sx={styles.containerInput}>
                          <Box style={{ display: 'none' }}>
                            <UploadField
                              label={capitalizeString('picture')}
                              name="picture"
                              folder={'users/'}
                              fileId={randomFileId()}
                              control={state.form.control}
                              ref={inputRef}
                              setDisabledButton={() => {}}
                              accept={'picture'}
                              wq
                              submitForm={actions.onUploadPicture}
                              onUploadSuccess={actions.updateImage}
                            />
                          </Box>
                          <Typography variant="h7">
                            {capitalizeString(t(APPLICATION_FORM_TITLE_SHARE_YOUR_PORTRAIT_PHOTO)).replace(
                              '{{expertLabel}}',
                              expertLabel
                            )}
                          </Typography>
                          <Stack sx={{ m: 1 }} />
                          <Grid alignItems={'center'} container spacing={1}>
                            <Grid item>
                              <Box
                                variant="flex"
                                role="button"
                                onClick={onClick}
                                sx={{
                                  cursor: 'pointer',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                  ':hover': { background: '#1407BF14' },
                                }}>
                                <CropOriginalIcon />
                                <Box sx={{ width: '7px' }} />
                                <Typography>{capitalizeString(t(ADD_PHOTO))}</Typography>
                              </Box>
                            </Grid>
                            {actions.watch('picture').length > 0 && (
                              <Grid item>
                                <Box sx={{ display: 'flex' }} size="small" onClick={() => {}}>
                                  <FileDownloadDoneIcon fontSize="small" />
                                  <Box sx={{ width: '10px' }} />
                                  <Typography sx={{}} variant="body2">
                                    {capitalizeString(t(IMAGE_UPLOADED_SUCCESS))}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                      {/*********** TargetList ***********/}
                      {validation?.targetList?.required && (
                        <Stack sx={styles.containerInput}>
                          <Typography variant="h7">
                            {capitalizeString(t(APPLICATION_FORM_TITLE_PROGRAM_GOALS))}
                          </Typography>
                          {state.scopes.isLoading ? (
                            <Skeleton />
                          ) : (
                            <MultiSelectNative
                              options={handleTransformListForOptionsSelectTargets(
                                company && company.target_template_items ? company.target_template_items : []
                              )}
                              ref={refTargetList}
                              name="targetList"
                              label={capitalizeString(t(APPLICATION_FORM_FIELD_SELECT_OBJECTIVES)) + '*'}
                              control={control}
                              rules={validation?.targetList}
                            />
                          )}
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                      {/*********** Expectations ***********/}

                      {validation?.expectations2?.required && (
                        <>
                          <Stack sx={styles.containerInput}>
                            <InputWithTooltip
                              width="100%"
                              top="60%"
                              tooltipText={t(APPFORM_TOOLTIP_EXPECTATIONS).replace('{{expertLabel}}', expertLabel)}>
                              <Box sx={styles.spacerLabelTop} />
                              <Typography variant="h7">
                                {capitalizeString(t(APPLICATION_FORM_TITLE_EXPECTATIONS_PROGRAM)).replace(
                                  '{{expertLabel}}',
                                  expertLabel
                                )}
                              </Typography>
                            </InputWithTooltip>

                            <Stack sx={{ m: 1 }} />
                            <Input
                              ref={refExpectations}
                              label={capitalizeString(t(APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE)) + '*'}
                              rows={3}
                              maxRows={4}
                              multiline
                              variant="outlined"
                              name="expectations2"
                              control={control}
                              rules={validation?.expectations2}
                            />
                            <Stack sx={{ m: 1 }} />
                          </Stack>
                        </>
                      )}
                      {/*********** Email leader ***********/}
                      {validation?.leaderEmail?.required && (
                        <Stack sx={styles.containerInput}>
                          <Typography variant="h7">{t(APPLICATION_FORM_MANAGER_EMAIL_LABEL)}</Typography>
                          <Box sx={styles.spacerLabelTop} />
                          <Box sx={styles.spacerLabelTop} />
                          <Box sx={styles.spacerLabelTop} />
                          <Input
                            ref={refEmailLeader}
                            label={t(PLACEHOLDER_MANAGER_EMAIL)}
                            name="leaderEmail"
                            control={control}
                          />
                          <Stack sx={{ m: 1 }} />
                        </Stack>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <></>
              )}
              <Stack sx={{ m: 1 }} />
              <Stack alignItems="flex-end">
                <Button
                  onClick={() => {
                    state.form.setFocus();
                    actions.handleSubmitApplication();
                  }}
                  type="primary">
                  {state.form.isLoadingApply ? (
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                  ) : (
                    t(APPLY_TO_PROGRAM)
                  )}
                </Button>
              </Stack>
            </>
          )
        )}
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={state.form.stateAlert.open}
        autoHideDuration={3000}
        onClose={() => state.form.setStateAlert((previousState) => ({ ...previousState, open: false }))}>
        <Alert
          severity={state.form.stateAlert.type}
          variant="filled"
          sx={{ width: '100%' }}
          onClose={() => state.form.setStateAlert((previousState) => ({ ...previousState, open: false }))}>
          {state.form.stateAlert.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ApplicationForm;
