const styles = {
  personIcon: {
    marginRight: '8px',
    color: 'primary.grey',
  },
  itemSelected: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'primary.grey',
  },
  arrowIcon: {
    marginLeft: '4px',
    color: 'primary.grey',
    fontSize: '1.6rem',
  },
  dropdownContainer: {
    zIndex: 1,
    width: '130px',

    ul: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
};
export default styles;
