const styles = {
  container: {
    maxWidth: '450px',
    height: 'max-content',
  },

  title: {
    fontSize: '28px',
    fontWeight: 600,
    paddingBottom: '16px',
  },

  subTitle: {
    color: '#545454',
    fontSize: '16px',
    fontWeight: 300,
    paddingBottom: '0px',
  },

  containerButtons: {
    paddingTop: '20px',
    textAlign: 'right',
  },
};

export default styles;
