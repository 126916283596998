import React, { useState } from 'react';
import { Avatar, Box, Typography, Chip } from '@mui/material';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import parse from 'html-react-parser';
import { roleList } from 'utils/constUtils';

import { smallDateWithTime } from 'utils/dateUtils';

import { useSpecialCompanyResourcesQuery } from 'hooks/queries';

import { useTranslation, Trans } from 'react-i18next';

import styles from './HighlightedResourceCard.styles';
import Button from 'ui/components/Button/Button';

const HighlightedResourceCard = ({ company, role }) => {
  const { t } = useTranslation();
  const { data: specialResources, isLoading: isLoadingSpecialResource } = useSpecialCompanyResourcesQuery();

  const typeCard = specialResources?.length ? 'default' : 'empty_state';

  const resource = specialResources?.[0] || {};

  const userLabel = role === roleList.COACH ? company.expertLabel : company.clientLabel;

  const onClickResource = () => {
    if (typeCard === 'empty_state') {
      window.open(`${process.env.REACT_APP_PATHLINE_RESSOURCES_LINK}`, '_blank');
    } else {
      window.open(resource.path, '_blank');
    }
  };

  const description = () => {
    if (typeCard === 'empty_state' || !resource?.description) {
      return (
        <Trans
          i18nKey={`highlighted_resource_card.${typeCard}.description`}
          components={{ strong: <Typography sx={{ fontWeight: 600, display: 'inline' }} /> }}
        />
      );
    }

    return parse(resource.description);
  };

  return (
    <DashboardCard isShowBoxShadow>
      <Box sx={styles.container}>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Typography sx={styles.title}>
            <Trans
              i18nKey={`highlighted_resource_card.${typeCard}.title`}
              components={{
                strong: (
                  <Typography sx={{ display: 'inline', fontWeight: 600, fontSize: '16px', color: 'primary.main' }} />
                ),
              }}
              values={{ userLabel }}
            />
          </Typography>

          {typeCard !== 'empty_state' && (
            <Chip variant="outlined" label={smallDateWithTime(resource.date)} sx={styles.timeChip} />
          )}
        </Box>

        <Typography sx={styles.description}>{description()}</Typography>

        <Box sx={styles.bottomContent}>
          {typeCard === 'empty_state' && (
            <Typography sx={styles.secondDescription}>
              <Trans
                i18nKey={`highlighted_resource_card.${typeCard}.second_description`}
                components={{ strong: <Typography sx={{ fontWeight: 600, display: 'inline' }} /> }}
              />
            </Typography>
          )}

          {typeCard !== 'empty_state' && <Box />}

          <Button variant="secondary" size="xlg" isGrey sx={styles.button} onClick={onClickResource}>
            {t(`highlighted_resource_card.${typeCard}.button`)}
          </Button>
        </Box>

        {typeCard === 'empty_state' && <AccessTimeIcon sx={styles.icon} />}
        {typeCard !== 'empty_state' && <VideocamOutlinedIcon sx={styles.icon} />}
      </Box>
    </DashboardCard>
  );
};

export default HighlightedResourceCard;
