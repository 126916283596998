const styles = {
  chip: {
    backgroundColor: '#C8ECD8',
    color: '#101828',
    height: '20px',
    fontSize: '12px',

    '.MuiChip-label': {
      fontWeight: '600',
      textTransform: 'uppercase',
      fontStyle: 'italic',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

export default styles;
