import React from 'react';

// Components
import Box from 'ui/components/system/Box';
import ViewModal from 'ui/components/shared/ViewModal';
import LoginForm from 'ui/pages/login/LoginForm';

// Hooks
import { useTranslation } from 'react-i18next';
import PoweredBy from 'ui/components/PoweredBy';
import { useNavigate } from 'react-router-dom';

const loginLogoCompany = {
  marginBottom: '32px',
  marginTop: '40px',
  maxHeight: '70px',
  maxWidth: '130px',
  width: 'auto',
  height: 'auto',
  objectFit: 'contain',
  mr: '5px',
};

const LoginModal = ({
  company,
  open,
  control,
  isOpenCompanyWarningModal,
  isEmailCompany,
  onSubmit,
  onSubmitMicrosoft,
  onSubmitCompany,
  onClickApplicationForm,
  onCloseCompanyWarningModal,
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ViewModal
      open={open}
      onClose={onClose}
      customStyleBox={{
        maxWidth: '600px',
        borderRadius: '16px',
        position: 'relative',
        height: 'none',
        maxHeight: '90%',
        padding: '20px 60px',
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {company?.logo && <Box component="img" src={company.logo} alt="Logo" sx={loginLogoCompany} />}
      </Box>
      <LoginForm
        activeSelfRegistrationLink
        control={control}
        subTitle={t('self_registration.login_subtitle')}
        isOpenCompanyWarningModal={isOpenCompanyWarningModal}
        isEmailCompany={isEmailCompany}
        onSubmit={onSubmit}
        onSubmitMicrosoft={onSubmitMicrosoft}
        onSubmitCompany={onSubmitCompany}
        onClickReset={() => {
          navigate('/login#reset');
        }}
        onClickApplicationForm={onClickApplicationForm}
        onCloseCompanyWarningModal={onCloseCompanyWarningModal}
      />

      <Box sx={{ marginTop: '32px', marginBottom: '16px', textAlign: 'center' }}>
        <PoweredBy />
      </Box>
    </ViewModal>
  );
};

export default LoginModal;
