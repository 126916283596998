import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useMeQuery } from 'hooks/queries';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import {
  MESSAGE_SUCCESS_CHANGE_PASSWORD,
  MESSAGE_SUCCESS_CHANGE_PROFILE,
  INVALID_CREDENTIALS_MESSAGE,
} from 'litterals';
import { useEffect } from 'react';
import { onErrorPassword } from 'utils/password';

export function useEditProfile() {
  const { setStateAlert } = useAlert();
  const { i18n, t } = useTranslation();

  const queryClient = useQueryClient();
  const { data, isLoading } = useMeQuery();

  const defaultValues = {
    firstname: data?.firstname || '',
    lastname: data?.lastname || '',
    email: data?.email || undefined,
    phoneNumber: data?.phoneNumber || undefined,
    role: data?.role || '',
  };

  const { handleSubmit, control, reset } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (data) {
      reset(defaultValues);

      // Saves the browser language for users who have not configured a language.
      const setLanguage = async () => {
        if (!isLoading && data?.userId && !data?.platformContentLanguage && i18n?.language?.split('-')?.[0]) {
          await changeLanguage(i18n.language.split('-')[0]);
        }
      };
      setLanguage();
    }
  }, [data]);

  const mutation = useMutation(api.Users.updateMe, {
    onSuccess: (data) => {
      localStorage.setItem('REACT_TOKEN_AUTH', data.token);
      queryClient.invalidateQueries(['get-me']);
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGE_PROFILE) });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const mutationLangue = useMutation(api.Users.updateMe, {
    onSuccess: (data) => {
      localStorage.setItem('REACT_TOKEN_AUTH', data.token);
      queryClient.invalidateQueries(['get-me']);
      setStateAlert({ open: true, message: t('message_success_change_language'), duration: 10000 });
    },
  });

  const submit = handleSubmit((data) => {
    mutation.mutate(data);
  });

  const changeLanguage = (lng) => {
    mutationLangue.mutate({ platformContentLanguage: lng });
  };

  const changeRole = (role) => {
    return mutation.mutate({ role });
  };

  return {
    state: { controlForm: control, query: { isLoading, data } },
    actions: { submit, changeLanguage, changeRole },
  };
}

export function useEditPassword() {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const { handleSubmit, control, setError, watch, reset } = useForm({
    mode: 'all',
    defaultValues: { oldPassword: '', newPassword: '', newPasswordConfirmation: '' },
  });

  const mutation = useMutation(api.Users.updateMePassword, {
    onSuccess: () => {
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGE_PASSWORD) });
      reset();
    },
    onError: (error) => {
      if (error?.response?.data?.code === 3004) {
        setError('oldPassword', { type: 'custom', message: t(INVALID_CREDENTIALS_MESSAGE) });
      } else {
        onErrorPassword(error, 'newPassword', setError, t);
      }
    },
  });

  const submit = handleSubmit(({ newPassword, oldPassword }) => mutation.mutate({ newPassword, oldPassword }));

  return {
    state: { controlForm: control, newPassswordValue: watch('newPassword') },
    actions: { submit },
  };
}

export function useActiveReminders() {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { data, isLoading } = useMeQuery();

  const defaultValues = { reminderActivated: data.reminderActivated || false };

  const { handleSubmit, control, reset } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (data) {
      reset(defaultValues);
    }
  }, [data]);

  const mutation = useMutation(api.Users.updateMe, {
    onSuccess: (data, payload) => {
      const type = payload.reminderActivated ? 'activated' : 'disabled';
      queryClient.invalidateQueries(['get-me']);
      setStateAlert({ open: true, message: t(`active_reminders_form.success_message.${type}`) });
    },
  });

  const submit = handleSubmit((data) => {
    mutation.mutate(data);
  });

  return { state: { controlForm: control, query: { isLoading, data } }, actions: { submit } };
}
