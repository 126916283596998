import React, { useRef, useState, useMemo } from 'react';
import { Box, Typography, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './RolesDropdown.styles';
import Button from 'ui/components/Button/Button';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { roleList } from 'utils/constUtils';
import { useEditProfile } from 'hooks/usecase/useProfile';
import { useController } from 'react-hook-form';

const RolesDropdown = ({ user }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(user.role);

  const rolesMapping = {
    [roleList.COACH]: user?.company?.expertLabel || t('role_coach'),
    [roleList.COACHEE]: user?.company?.clientLabel || t('role_coachee'),
    [roleList.RH]: roleList.ADMIN,
    [roleList.RH_MANAGER]: roleList.ADMIN,
    [roleList.ADMIN]: roleList.ADMIN,
  };

  const options = user.availableRoles.map((element) => {
    return { id: element, label: rolesMapping[element] };
  });

  const anchorRef = useRef(null);

  const {
    state: { query, controlForm },
    actions,
  } = useEditProfile();

  const defaultValue = useMemo(() => user?.role || '', [user?.role]);

  const {
    field: { onChange, value },
  } = useController({ control: controlForm, name: 'role', defaultValue });

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event, role) => {
    onChange(role);
    setSelectedOption(role);
    actions.submit();
    setOpen(false);
  };

  return (
    <>
      <Box ref={anchorRef}>
        <Button variant="tertiary" onClick={handleToggle}>
          <PersonOutlineOutlinedIcon sx={styles.personIcon} />

          <Typography sx={styles.itemSelected}>{rolesMapping[user.role]}</Typography>

          <KeyboardArrowDownOutlinedIcon sx={styles.arrowIcon} />
        </Button>
      </Box>

      <Popper
        sx={styles.dropdownContainer}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      name="role"
                      key={option.id}
                      selected={option.id === selectedOption}
                      control={controlForm}
                      onClick={(event) => handleMenuItemClick(event, option.id)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default RolesDropdown;
