import React, { useState } from 'react';
import { Avatar, Chip, Box, Typography, LinearProgress, Tooltip } from '@mui/material';
import Button from 'ui/components/Button/Button';
import { useTranslation } from 'react-i18next';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { parseJsonTranslate } from 'utils/parse';
import { roleList } from 'utils/constUtils';
import { smallDate, smallTime } from 'utils/dateUtils';
import { useNavigate } from 'react-router-dom';

import styles from './NextSessionLine.styles';

function LinearProgressWithLabel({ sessionValidated, sessionTotal, showTooltipProgress }) {
  const { t } = useTranslation();

  const value = (sessionValidated / sessionTotal) * 100;

  const textContent = t('next_session_card.default.session', {
    count: sessionValidated,
    sessionValidated,
    sessionTotal,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}>
      <Tooltip title={showTooltipProgress ? textContent : ''}>
        <Box sx={{ width: showTooltipProgress ? '40px' : '90px', mr: 1 }}>
          <LinearProgress variant="determinate" sx={styles.progressBar} value={value} />
        </Box>
      </Tooltip>
      {!showTooltipProgress && (
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" sx={styles.progressBarText}>
            {textContent}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const NextSessionLine = ({
  session,
  showTooltipProgress,
  onClickUnplannedButton,
  onClickShowProfile,
  showUserType = roleList.COACH,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isUnplannedSession = !session.date;

  const userInfo =
    showUserType === roleList.COACH
      ? { ...(session?.program?.coach || {}), ...(session?.program?.coach?.user || {}) }
      : session.program.coachee || session.program.guest;

  const onClick = () => {
    navigate(`/program/${session.program.id}#session-${session.id}`);
  };

  const sessionTotal = session?.program?.sessions?.length;
  const sessionValidated = session?.program?.sessions?.filter(({ done }) => done).length;

  const onClickProfile = (event) => {
    if (showUserType === roleList.COACH && session?.program?.coach?.id) {
      onClickShowProfile({ type: roleList.COACH, id: session?.program?.coach?.id });
      event.stopPropagation();
    } else if (session?.program?.coachee?.id) {
      onClickShowProfile({ type: roleList.COACHEE, id: session?.program?.coachee?.id });
      event.stopPropagation();
    }
  };

  return (
    <Box sx={styles.container} onClick={onClick}>
      {!isUnplannedSession && (
        <Box>
          <Typography sx={styles.date}>{smallDate(session.date)}</Typography>
          <Typography sx={styles.time}>{smallTime(session.date)}</Typography>
        </Box>
      )}

      {isUnplannedSession && (
        <Box>
          <Typography sx={styles.unplannedSession}>{t('next_session_card.default.unplanned')}</Typography>
        </Box>
      )}

      <Box sx={styles.border} />

      <Box sx={styles.programContainer}>
        <Typography sx={styles.sessionTitle}>{session.name}</Typography>
        <Typography sx={styles.themeTitle}>{parseJsonTranslate(session?.program?.theme?.name)}</Typography>
      </Box>

      <Box>
        <Box sx={styles.userContainer} onClick={onClickProfile}>
          <Avatar src={userInfo?.picture || ''} sx={styles.userAvatar} />

          <Typography sx={styles.userName}>{t(`${userInfo?.firstname || ''} ${userInfo?.lastname || ''}`)}</Typography>
        </Box>
      </Box>

      {sessionTotal !== 0 && (
        <Box sx={styles.progressContainer}>
          <LinearProgressWithLabel
            showTooltipProgress={showTooltipProgress}
            sessionTotal={sessionTotal}
            sessionValidated={sessionValidated}
          />
        </Box>
      )}

      {!isUnplannedSession && !!session?.duration && (
        <Box>
          <Chip sx={styles.chip} label={`${session.duration} min`} variant="outlined" />
        </Box>
      )}

      {isUnplannedSession && (
        <Box sx={styles.unplannedButtonContainer}>
          <Button size="xs" sx={{ height: '32px' }} onClick={onClickUnplannedButton}>
            <CalendarTodayIcon sx={styles.calendarIcon} />
            {t('next_session_card.default.unplanned_button')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NextSessionLine;
