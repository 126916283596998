import React, { useState } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { useGuests } from 'hooks/usecase/useGuests';
import DataGrid from 'ui/components/DataGrid';
import { tableSchemaBase as columns } from './Guests.config';
import { getStringFromList } from 'utils/languages';
import dayjs from 'dayjs';
import { useMeQuery } from 'hooks/queries';
import useFormSettings from 'hooks/usecase/useFormSettings';
import { useTranslation } from 'react-i18next';

const Guests = ({ onRowClicked }) => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState({ page: 1, size: 25 });
  const { data: user } = useMeQuery();
  const qParams = { isMentor: true };
  const { formSettingsList } = useFormSettings(user.company.id, qParams);
  // Hooks
  const { guests, isLoading, totalPages, totalItems, currentPage } = useGuests(queryParams);

  const updateList = (users) =>
    users.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        languages: currentItem.languages ? getStringFromList(currentItem.languages) : '',
        entity: currentItem.source,
        source: currentItem.program_count > 0 ? 'YES' : 'NO',
        disabled: currentItem.typeGuest === 'RH_Validation',
        tooltip: currentItem.typeGuest === 'RH_Validation' ? t('cannot_be_selected') : '',
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, scopeId: filter.value };
            break;
          case 'user':
            // eslint-disable-next-line no-case-declarations
            let queryParamsFilterName = {};
            if (filter && filter.value && filter.value.length > 0) {
              var [premierMot, ...resteChaine] = filter.value.split(' ');
              if (resteChaine.length > 0) {
                queryParamsFilterName = { firstname: premierMot, lastname: resteChaine.join(' ') };
              } else {
                queryParamsFilterName = { firstname: premierMot };
              }
            }
            queryParamsFilter = { ...queryParamsFilter, ...queryParamsFilterName };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'scopeId' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({
      ...queryParams,
      page: currentPage,
      size: currentSize,
      ...queryParamsSort,
    });
  };

  function checkAllEmpty(array, attribute) {
    return array.every(function (object) {
      return object[attribute] == '-' || object[attribute] == null;
    });
  }

  const filterColumnFromSettings = (list) => {
    const EXCLUDE_LIST = [];
    formSettingsList?.formFields?.map((item) => {
      switch (item.fieldName) {
        case 'spokenLanguages':
          if (!item.isActivated) EXCLUDE_LIST.push('languages');
          break;
        case 'sector':
          if (!item.isActivated) EXCLUDE_LIST.push('scopeName');
          break;
        case 'genderIdentity':
          if (!item.isActivated) EXCLUDE_LIST.push('gender');
          break;
      }
    });
    return list.filter((item) => !EXCLUDE_LIST.includes(item.field));
  };

  const getColumns = () => {
    let filteredColumns = [...columns];
    if (guests && Array.isArray(updateList(guests)) && checkAllEmpty(updateList(guests), 'gender')) {
      filteredColumns = columns.filter((col) => col.field !== 'gender');
    }

    return filterColumnFromSettings(filteredColumns);
  };

  return (
    <Stack sx={{ padding: '5px 0%', height: '430px', 'div.disabled': { opacity: 0.3, cursor: 'pointer !important' } }}>
      <DataGrid
        key={`${currentPage}-${totalItems}`}
        columns={getColumns()}
        rows={updateList(guests)}
        totalItems={totalItems}
        currentPage={currentPage || 1}
        getRowClassName={(params) => {
          if (params?.row?.disabled) return 'disabled';
        }}
        onRowClicked={(guest, event) => {
          if (guest?.row?.typeGuest === 'RH_Validation') return;
          if (!event.defaultMuiPrevented) {
            let updatedGuest = guest.row;
            updatedGuest.source = guest.row.entity;
            onRowClicked(updatedGuest);
          }
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        totalPages={totalPages ? totalPages : 0}
        loading={isLoading}
      />
    </Stack>
  );
};

export default Guests;
