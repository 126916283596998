import React, { useState } from 'react';
import { Avatar, Box, Tooltip } from '@mui/material';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import Button from 'ui/components/Button/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { LinearProgress, Typography, Chip } from '@mui/material';
import { roleList } from 'utils/constUtils';
import ActiveSettingsCoachModal from 'ui/pages/coach/ActiveSettingsCoachModal';
import CoachActivationSwitch from 'ui/components/shared/coaches/CoachActivationSwitch';
import useEditCoach from 'hooks/usecase/useEditCoach';
import { useTranslation } from 'react-i18next';
import CalendarConnectionButton from 'ui/components/shared/CalendarConnectionButton/CalendarConnectionButton';
import { useCoachAvailabilities, useListFormSettingsPublic } from 'hooks/queries';
import AvailabilityChip from 'ui/components/shared/AvailabilityChip/AvailabilityChip';
import AvailabilityManagementModal from 'ui/components/shared/coaches/AvailabilityManagementModal/AvailabilityManagementModal';
import MentoringAvailableChip from 'ui/components/shared/MentoringAvailableChip/MentoringAvailableChip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';

import styles from './ProfileCompletionCard.styles';
import { computeCoachProfileCompletionRate, computeCoacheeProfileCompletionRate } from 'utils/profileCompletion';

const ProfileCompletionCard = ({ user, userCalCom, isLoadingCalendarConnected }) => {
  const { t } = useTranslation();
  const [openActiveSettings, setOpenActiveSettings] = useState(false);
  const [openAvailabilityManagement, setOpenAvailabilityManagement] = useState(false);
  const navigate = useNavigate();

  const { actions, state } = useEditCoach(user.id, user.companyId, user.role !== roleList.COACH);

  const isShowAvailabilities = !!user?.company?.selfRegistrationActivated && !!user.id && user.role === roleList.COACH;

  const { data: availabilities, isLoading: isLoadingAvailabilities } = useCoachAvailabilities(
    user.id,
    true,
    isShowAvailabilities
  );

  const { data: formSettingsList } = useListFormSettingsPublic({
    _Link: user?.company?.applicationLink,
    queryParams: { isMentor: user.role === roleList.COACH },
  });

  const isCalendarConnected = userCalCom?.isCalendarConnected || false;

  const onChangeActive = (e) => {
    setOpenActiveSettings(true);
  };

  const onClickCompleteProfile = () => {
    if (user.role === roleList.COACH) {
      navigate(`/coach/${user.id}`);
    } else {
      navigate(`/coachee/${user.id}`);
    }
  };

  const { sessionLabel } = user.company;

  const { score: calculateProfileProgress, propertiesNoCompleted } =
    user.role === roleList.COACH
      ? computeCoachProfileCompletionRate(formSettingsList, user, isCalendarConnected)
      : computeCoacheeProfileCompletionRate(formSettingsList, user, isCalendarConnected);

  const isActionNeeded = calculateProfileProgress !== 100;

  const tooltipAccountCompletionProfile = `${t('profile_completion_card.tootlip_completion_profile.desc')}
    ${propertiesNoCompleted
      .map((property) => `- ${t(`profile_completion_card.tootlip_completion_profile.property.${property}`)}`)
      .join('\n')}
  `;

  return (
    <DashboardCard isActionNeeded={isActionNeeded}>
      <Box sx={styles.container}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={styles.userInfoContainer}>
            <Avatar src={user.picture} sx={styles.avatar} />
            <Box sx={styles.userTextualInfoContainer}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={styles.userName}>{`${user.firstname} ${user?.lastname?.toUpperCase()}`}</Typography>

                {user.role === roleList.COACH && (
                  <Box>
                    <CoachActivationSwitch
                      active={user.active}
                      deactivatedFrom={user.deactivatedFrom}
                      deactivatedUntil={user.deactivatedUntil}
                      onChange={onChangeActive}
                    />

                    {openActiveSettings && state.form.control && (
                      <ActiveSettingsCoachModal
                        open={openActiveSettings}
                        control={state.form.control}
                        coach={user}
                        setValue={actions.setValue}
                        watch={actions.watch}
                        onClose={() => {
                          actions.resetActive();
                          setOpenActiveSettings(false);
                        }}
                        onValidate={() => {
                          setOpenActiveSettings(false);
                          actions.submitForm();
                        }}
                      />
                    )}
                  </Box>
                )}
              </Box>

              {isShowAvailabilities && !isLoadingAvailabilities && (
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <AvailabilityChip count={availabilities.length} showIcon sx={{ marginBottom: 0 }} />
                  <Button
                    sx={styles.availabilityButton}
                    variant="tertiary"
                    notification={!availabilities.length}
                    onClick={() => setOpenAvailabilityManagement(true)}>
                    {t('profile_completion_card.buttons.availability_button')}
                  </Button>

                  <AvailabilityManagementModal
                    coachId={user.id}
                    open={openAvailabilityManagement}
                    onClose={() => setOpenAvailabilityManagement(false)}
                  />
                </Box>
              )}

              {!isShowAvailabilities && user?.role === roleList.COACH && (
                <MentoringAvailableChip user={user} sessionLabel={sessionLabel} />
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress sx={styles.linearProgress} variant="determinate" value={calculateProfileProgress} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={styles.linearProgressContent}>
              {`${Math.round(calculateProfileProgress)}% ${t('profile_completion_card.your_completed_profile')}`}
            </Typography>

            {isActionNeeded && (
              <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipAccountCompletionProfile}</span>}>
                <InfoOutlinedIcon sx={{ fontSize: '18px', marginLeft: '3px', color: 'primary.grey' }} />
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box sx={styles.buttonsContainer}>
          {!isCalendarConnected && !isLoadingCalendarConnected && <CalendarConnectionButton />}

          {(isCalendarConnected || isLoadingCalendarConnected) && <Box />}

          <Button variant="secondary" notification={isActionNeeded} isGrey onClick={onClickCompleteProfile}>
            <EditOutlinedIcon sx={{ marginRight: '6px' }} />

            {t(
              `profile_completion_card.buttons.${
                calculateProfileProgress !== 100 ? 'complete_profile' : 'update_profile'
              }`
            )}
          </Button>
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default ProfileCompletionCard;
