import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useCoachQuery } from 'hooks/queries';
import { DateTime } from 'luxon';
import { useAlert } from 'common/alertContext';
import { MESSAGE_SUCCESS_CHANGE_PROFILE } from 'litterals';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useScopeList from './useScopeList';
import { useGetCompany } from 'hooks/queries';
export const DEFAULT_SKILLS = ['Performance & Transformation', 'Diversity & Inclusion', 'Leadership & Management'];

const defaultValues = {
  title: '',
  city: '',
  country: '',
  address: '',
  phoneNumber: '',
  isMentor: true,
  languagesSpoken: [],
  price: 0, // TODO : check with Pathline
  videoLink: '',
  descriptionExperience: '',
  coachingSkills: [],
  calendarLink: '',
  linkedinUrl: '',
  birthDate: undefined,
  nationality: '',
  hoursOfExperience: '',
  active: false,
  coachType: false,
  picture: '',
  userScope: '',
  seniorityLevelId: '',
  gender: '',
  internalCoach: false,
  maxCapacity: 1,
  idNumber: '',
  departmentId: '',
};

export default function useEditCoach(coachId, companyId, disabled, refetch) {
  if (disabled) return { actions: {}, state: { form: {} } };

  const queryClient = useQueryClient();
  const { data, isLoading } = useCoachQuery(coachId);
  const navigate = useNavigate();

  const { simpleScopeOption } = useScopeList();

  const { data: company } = useGetCompany(companyId);

  const updateImage = () => {
    queryClient.invalidateQueries(['get-coach']);
  };

  const deleteImage = async () => {
    await mutation.mutate({ id: coachId, data: { picture: '' } });
    queryClient.invalidateQueries(['get-coach']);
  };

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    resetField,
    formState: { isDirty },
  } = useForm({ mode: 'all', defaultValues });
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      console.log('API response for coach data:', data.internalCoach); // Log the API response
    }
    reset({
      firstname: data?.firstname || undefined,
      lastname: data?.lastname || undefined,
      email: data?.email || undefined,
      title: data?.title || undefined,
      city: data?.city || undefined,
      country: data?.country || undefined,
      address: data?.address || undefined,
      phoneNumber: data?.phoneNumber || undefined,
      isMentor: true,
      languagesSpoken: data?.languagesSpoken || [],
      price: data?.price || 0, // TODO : check with Pathline
      videoLink: data?.videoLink || undefined,
      descriptionExperience: data?.descriptionExperience || '',
      coachingSkills: data?.coachingSkills || [],
      calendarLink: data?.calendarLink || undefined,
      linkedinUrl: data?.linkedinUrl || undefined,
      birthDate: data?.birthDate || undefined,
      nationality: data?.nationality || undefined,
      hoursOfExperience: data?.hoursOfExperience || '',
      active: data?.active || false,
      coachType: data?.coachType || false,
      picture: data?.picture || '',
      userScope: data?.userScope || '',
      gender: data?.gender || '',
      internalCoach: data?.internalCoach === true || data?.internalCoach === 'true',
      maxCapacity: data?.maxCapacity || '',
      seniorityLevelId: data?.seniorityLevel?.id || '',
      timeZone: data?.timeZone || '',
      expectations: data?.expectations || '',
      deactivatedFrom: data?.deactivatedFrom || undefined,
      deactivatedUntil: data?.deactivatedUntil || undefined,
      canExceedMaxCapacity: data?.canExceedMaxCapacity || false,
      idNumber: data?.idNumber || '',
      departmentId: data?.departmentId || '',
    });
  }, [data, reset]);

  const onNavigate = () => {
    navigate(`/coach/${coachId}`);
  };

  // console.log("INTERNALCOACH=",data.internalCoach)

  const mutation = useMutation(api.Coaches.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGE_PROFILE) });
      refetch && refetch();
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const submitForm = handleSubmit(async (data) => {
    if (data.birthDate) {
      data.birthDate = DateTime.fromISO(data.birthDate).toSQLDate();
    } else {
      data.birthDate = null;
    }

    if (!data.departmentId) {
      delete data.departmentId;
    }

    if (!!data.picture && typeof data.picture !== 'string') {
      const { url } = await api.Users.upload(data.picture);
      data.picture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
    } else {
      delete data.picture;
    }

    data = {
      ...data,
      gender: data.gender !== '' ? data.gender : null,
      seniorityLevelId: data.seniorityLevelId !== '' ? data.seniorityLevelId : null,
      internalCoach: data.internalCoach === true || data.internalCoach === 'true',
    };

    if (data.deactivatedFrom) {
      data.deactivatedFrom = DateTime.fromISO(data.deactivatedFrom).toSQLDate();
    }

    if (data.deactivatedUntil) {
      data.deactivatedUntil = DateTime.fromISO(data.deactivatedUntil).toSQLDate();

      if (!data.deactivatedFrom) {
        data.deactivatedFrom = DateTime.fromISO(new Date().toISOString()).toSQLDate();
      }
    }

    mutation.mutate({ id: coachId, data });
    updateImage();
  });

  const resetActive = () => {
    setValue('active', data.active);
    setValue('deactivatedFrom', data.deactivatedFrom);
    setValue('deactivatedUntil', data.deactivatedUntil);
  };

  return {
    state: {
      form: { control, resetField, isDirty },
      query: { data, isLoading, scopeOption: simpleScopeOption, company },
    },
    actions: { watch, submitForm, onNavigate, updateImage, deleteImage, setValue, resetActive },
  };
}
