const styles = {
  container: {
    height: 'max-content',
    width: '700px',
  },
  containerCal: {
    height: 'max-content',
    maxHeight: '90%',
    maxWidth: '90%',
    width: 'max-content',
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '42px',
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  col: {
    display: 'flex',
  },
  durationInput: {
    backgroundColor: 'rgba(238, 238, 238, 1)',
    width: '59.17px',
    outline: 'none',
  },
  icon: {
    height: '14px',
    width: '14px',
  },
  spacerHorizontal: {
    width: '15.98px',
  },
  spacerVertical: {
    paddingTop: '42.24px',
  },
  content: {
    width: '644px',
    height: '401px',
    borderRadius: '16px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '30px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  calendarButton: {
    marginTop: '10px',
    width: '100%',
    backgroundColor: 'background.paper',
    color: 'rgba(0, 0, 0, 1)',
    borderColor: 'black',
  },
  bottomContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '32px',
  },
  cancelButton: {
    border: '1px solid rgba(0, 0, 0, 0.87)',
    width: '107.18px',
    height: '39px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
  },
  cancelText: {
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Karla',
    lineHeight: '23.38px',
    color: 'rgba(0, 0, 0, 1)',
  },
  validateButton: {
    marginLeft: '16px',
  },
};
export default styles;
