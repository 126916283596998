import React from 'react';
import { useUserDetail, useMeQuery } from 'hooks/queries';
import MenteeDetailModal from 'ui/components/shared/MenteeDetailModal/MenteeDetailModal';

const MenteeDetailModalWithRequest = ({ userId, user, ...props }) => {
  const { data: userFetched } = useMeQuery({ enabled: !user });
  const { data: userData } = useUserDetail(userId);

  const userConnectedData = user || userFetched;

  return <MenteeDetailModal user={userData} company={userConnectedData.company} {...props} />;
};

export default MenteeDetailModalWithRequest;
