import React from 'react';

import styles from './Button.styles';

import { Button as NativButton, CircularProgress, Box } from '@mui/material';

const Button = ({
  children,
  sx,
  size = 'lg',
  variant = 'primary',
  greyWithHover = false,
  loading = false,
  notification = false,
  isGrey = false,
  ...props
}) => {
  const getType = () => {
    switch (variant) {
      case 'primary':
        return styles.primary;
      case 'secondary':
        return styles.secondary;
      case 'tertiary':
        return styles.tertiary;
      case 'deletePrimary':
        return styles.deletePrimary;
      case 'delete':
      case 'delete-tertiary':
        return styles.delete;
      default:
        break;
    }
  };

  const getVariant = () => {
    switch (variant) {
      case 'primary':
        return 'contained';
      case 'secondary':
      case 'delete':
        return 'outlined';
      case 'tertiary':
      case 'delete-tertiary':
        return 'text';
      default:
        break;
    }
  };

  const getSize = () => {
    switch (size) {
      case 'xlg':
        return styles.xlg;
      case 'lg':
        return styles.lg;
      case 'xs':
        return styles.xs;
      default:
        break;
    }
  };

  const getOthers = () => {
    if (greyWithHover) return styles.greyWithHover;
    if (isGrey) return styles.grey;
  };

  return (
    <NativButton variant={getVariant()} sx={[styles.button, getType(), getSize(), getOthers(), sx]} {...props}>
      {loading && <CircularProgress color="inherit" size="22px" sx={{ marginRight: '8px' }} />}
      <span style={styles.content}>{children}</span>
      {notification && <Box sx={styles.notification} />}
    </NativButton>
  );
};

export default Button;
